<template>
<div class="subscribe">
    <div class="title">
    <h2>Unlock Exclusive Updates</h2>
    <p>Subscribe now!</p>
    </div>
    <form @submit.prevent="subscribe">
    <input type="text" v-model="form.name" placeholder="Enter your names" required>
    <div class="flex-input">
        <input type="email" v-model="form.email" placeholder="Enter your email" required>
        <button>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><path d="M39.175,10.016c1.687,0,2.131,1.276,1.632,4.272c-0.571,3.426-2.216,14.769-3.528,21.83 c-0.502,2.702-1.407,3.867-2.724,3.867c-0.724,0-1.572-0.352-2.546-0.995c-1.32-0.872-7.984-5.279-9.431-6.314 c-1.32-0.943-3.141-2.078-0.857-4.312c0.813-0.796,6.14-5.883,10.29-9.842c0.443-0.423,0.072-1.068-0.42-1.068 c-0.112,0-0.231,0.034-0.347,0.111c-5.594,3.71-13.351,8.859-14.338,9.53c-0.987,0.67-1.949,1.1-3.231,1.1 c-0.655,0-1.394-0.112-2.263-0.362c-1.943-0.558-3.84-1.223-4.579-1.477c-2.845-0.976-2.17-2.241,0.593-3.457 c11.078-4.873,25.413-10.815,27.392-11.637C36.746,10.461,38.178,10.016,39.175,10.016 M39.175,7.016L39.175,7.016 c-1.368,0-3.015,0.441-5.506,1.474L33.37,8.614C22.735,13.03,13.092,17.128,6.218,20.152c-1.074,0.473-4.341,1.91-4.214,4.916 c0.054,1.297,0.768,3.065,3.856,4.124l0.228,0.078c0.862,0.297,2.657,0.916,4.497,1.445c1.12,0.322,2.132,0.478,3.091,0.478 c1.664,0,2.953-0.475,3.961-1.028c-0.005,0.168-0.001,0.337,0.012,0.507c0.182,2.312,1.97,3.58,3.038,4.338l0.149,0.106 c1.577,1.128,8.714,5.843,9.522,6.376c1.521,1.004,2.894,1.491,4.199,1.491c2.052,0,4.703-1.096,5.673-6.318 c0.921-4.953,1.985-11.872,2.762-16.924c0.331-2.156,0.603-3.924,0.776-4.961c0.349-2.094,0.509-4.466-0.948-6.185 C42.208,7.875,41.08,7.016,39.175,7.016L39.175,7.016z"/></svg>
            Subscribe
        </button>
    </div>
    </form>
</div>
</template>
<script>
export default {
    name: 'Subscribe',
    data() {
        return {
            form: {
                name: '',
                email: ''
            }
        }   
    },
    methods: {
        subscribe() {
            this.$store.dispatch('CREATE_SUBSCRIPTION', this.form)
                .then(res => {
                    if(res.data.message == 'Subscription created successfully'){
                        this.$notify({
                            text: res.data.message,
                            type: 'success'
                        })
                        this.form.name = '';
                        this.form.email = '';
                    }else{
                        this.$notify({
                            text: res.data.message,
                            type: 'error'
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "../../scss/main.scss";
    .subscribe {
        background: #f5f5f5;
        width: 100%;
        display: flex;
        padding: 30px 0;
        justify-content: space-around;
        @media (max-width: 1000px) {
            flex-direction: column;
            row-gap: 20px;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    .title{
        h2{
            color: $darkorange;
            font-size: 1.2rem;
        }
        p{
            color: $black;
            font-size: 0.8rem;
        }
    }
    form{
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            justify-content: center;
            padding: 0 10px;
            width: 100%;
            row-gap: 20px;
            flex-direction: column;
        }
        .flex-input{
            display: flex;
        }
        input{
            border: 1px solid $darkorange;
            padding: 10px 20px;
            border-radius: 4px 0 0 4px;
            outline: none;
            font-size: 0.75rem;
            width: 300px;
            &[type="text"]{
                width: 200px;
                margin-right: 10px;
                border-radius: 4px;
            }
            @media (max-width: 768px) {
                padding: 10px 10px;
            }
            @media (max-width: 500px) {
                width: 70%;
            }
        }
        button{
            outline: none;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 0 4px 4px 0;
            font-size: 0.8rem;
            column-gap: 10px;
            background: $darkorange;
            border: none; 
            justify-content: center;
            color: #fff;
            white-space: nowrap;
            svg{
                fill: #fff;
                width: 20px;
                height: 20px;
            }
        }
    }
</style>
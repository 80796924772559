<template>
<div class="overlay">
    <form class="form" @submit.prevent="submitform()">
        <div class="top">
            <h3 class="title">{{ modalform.type }} Project Proposal</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="(modalform.type == 'add' || modalform.type == 'edit') && form">
            <label>Project name</label>
            <input type="text" v-model="form.name" placeholder="Enter names" required>
            <label>Project type</label>
            <select v-model="form.project_type" required>
                <option value="" selected hidden>Select Project Type</option>
                <option value="personal">Personal</option>
                <option value="company">Company</option>
            </select>
            <div class="flex" v-if="form.project_type === 'company'">
                <div>
                    <label>Company name</label>
                    <input type="text" placeholder="Enter company name" v-model="form.company_name">
                </div>
                <div>
                    <label>Company email</label>
                    <input type="email" placeholder="Enter company email" v-model="form.company_email" required>
                </div>
            </div>
            <div class="flex" v-if="form.project_type === 'company'">
                <div>
                    <label>Company phone</label>
                    <input type="text" placeholder="Enter company phone" v-model="form.company_phone">
                </div>
                <div>
                    <label>Company address</label>
                    <input type="text" placeholder="Enter company address" v-model="form.company_address">
                </div>
            </div>
            <label v-if="form.project_type === 'company'">Position</label>
            <input v-if="form.project_type === 'company'" placeholder="Enter person position" type="text" v-model="form.position">
            <div class="flex" v-if="form.project_type === 'personal' || form.project_type === 'company'">
                <div>
                    <label>Focal Person Name</label>
                    <input type="text" placeholder="Enter names" v-model="form.person_name" required>
                </div>
                <div>
                    <label>Focal Person Phone</label>
                    <input type="text" placeholder="Enter phone" v-model="form.person_phone">
                </div>
            </div>
            <div v-if="form.project_type == 'personal'">
                <label>Focal Person Email</label>
                <input type="text" placeholder="Enter email" v-model="form.person_email">
            </div>
            <label>Project service</label>
            <select v-model="form.service_id" required>
                <option value="" selected hidden>Select Service</option>
                <option :value="service.id" v-for="service in services" :key="service.id">{{ service.name }}</option>
            </select>
            <div v-if="hosting">
                <label>Hosting</label>
                <select v-model="form.hosting_id" required>
                    <option value="" selected>Select Hosting plan</option>
                    <option :value="plan.id" v-for="plan in plans" :key="plan.id">{{ plan.name }} (${{ plan.price }})</option>
                    <option :value="0">Custom</option>
                </select>
                <label>Domain name</label>
                <input type="text" placeholder="Enter domain name" v-model="form.domain">
                <label>Domain expiry date</label>
                <input type="date" v-model="form.domain_exp">
            </div>
            <div v-if="form.hosting_id == 0">
                <label>Custom hosting fees</label>
                <input type="number" placeholder="Enter hosting fees" v-model="form.hosting_fees">
            </div>
            <label>Project description</label>
            <textarea v-model="form.description" placeholder="Write message..." rows="4" required></textarea>
            <!-- <VueEditor v-model="form.description"></VueEditor> -->
            <!-- <vue-editor></vue-editor> -->
            <label>Development Cost</label>
            <input type="text" v-model="form.development_cost" placeholder="Enter project budget" required>
            <label>Project Status</label>
            <select v-model="form.status" required>
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                <option value="On Hold">On Hold</option>
                <option value="Testing">Testing</option>
                <option value="Completed">Completed</option>
                <option value="Delivered">Delivered</option>
            </select>
            <label>Start Date</label>
            <input type="date" v-model="form.start_date">
            <div v-if="form.status == 'Delivered'">
                <label>Delivery Date</label>
                <input type="date" v-model="form.delivery_date">
            </div>
        </div>
        <label class="warn-text" v-if="modalform.type == 'delete' || modalform.type == 'approve' || modalform.type == 'decline'">Are you sure you want to {{ modalform.type }} this project ?</label>
        <button v-if="modalform.type != 'view'">{{ modalform.type }} Project</button>
    </form>
</div>
</template>

<script>
export default {
    name: "ProposalModal",
    props: {
        current: {
            type: Object,
            default: null
        },
        modalform: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            services: [],
            plans: [],
        }
    },
    computed: {
        hosting(){
            if(this.form.service_id){
                return this.services.find(service => service.id == this.form.service_id)?.name == 'Web development & Hosting'
            }
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = this.current
        },
        submitform(){
            this.loading = true
            if(this.modalform.type == "approve"){
                this.$store.dispatch("APPROVE_PROJECT_REQUEST", this.form.id)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.loading = false
                    this.$emit("close")
                    this.$emit("refresh", 'approved')
                })
                .catch(err => {
                    console.log(err);
                })
            }else if(this.modalform.type == "decline"){
                this.$store.dispatch("DECLINE_PROJECT_REQUEST", this.form.id)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.loading = false
                    this.$emit("close")
                    this.$emit("refresh", 'declined')
                })
                .catch(err => {
                    console.log(err);
                })
            } else if(this.modalform.type == 'add'){
                this.form.hosting_id == 0 ? this.form.hosting_id = null : this.form.hosting_fees = null;
                this.$store.dispatch('CREATE_PROJECT_REQUEST', this.form)
                    .then((res) => {
                        if(res.data.message == 'Project created successfully'){
                            this.$notify({
                                type: 'success',
                                text: res.data.message
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'active')
                        } else {
                            this.$notify({
                                type: 'error',
                                text: res.data.message
                            })
                        }
                    })
            } else if(this.modalform.type == 'delete'){
                this.$store.dispatch('DELETE_PROJECT_REQUEST', this.form.id)
                .then((res) => {
                    if(res.data.message == 'Project deleted successfully'){
                        this.$notify({
                            type: 'success',
                            text: res.data.message
                        })
                        this.loading = false
                        this.$emit("close")
                        this.$emit('refresh', 'active')
                    } else {
                        this.$notify({
                            type: 'error',
                            text: res.data.message
                        })
                    }
                })
            } else if(this.modalform.type == 'edit'){
                this.form.hosting_id == 0 ? this.form.hosting_id = null : this.form.hosting_fees = null;
                this.$store.dispatch('UPDATE_PROJECT_REQUEST', this.form)
                    .then((res) => {
                        if(res.data.message == 'Project updated successfully'){
                            this.$notify({
                                type: 'success',
                                text: res.data.message
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'active')
                        } else {
                            this.$notify({
                                type: 'error',
                                text: res.data.message
                            })
                        }
                    })
            }
        }
    },
    mounted() {
        this.handelform()
        this.$getAllServices()
        this.$getAllHostingPlans()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
import axios from 'axios';
import { GET_ALL_TESTIMONIALS_ACTIVE, CREATE_TESTIMONIAL, UPDATE_TESTIMONIAL, DELETE_TESTIMONIAL, GET_ALL_TESTIMONIALS_REQUESTS, APPROVE_TESTIMONIAL, DECLINE_TESTIMONIAL } from '../actions/testimonials';
import { GET_ALL_TESTIMONIALS_ACTIVE_URL, GET_ALL_TESTIMONIALS_REQUESTS_URL, CREATE_TESTIMONIAL_URL, DELETE_TESTIMONIAL_URL, UPDATE_TESTIMONIAL_URL, APPROVE_TESTIMONIAL_URL, DECLINE_TESTIMONIAL_URL } from '../variables';

const testimonials = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_TESTIMONIALS_ACTIVE]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_TESTIMONIALS_ACTIVE_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [GET_ALL_TESTIMONIALS_REQUESTS]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_TESTIMONIALS_REQUESTS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [CREATE_TESTIMONIAL]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CREATE_TESTIMONIAL_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_TESTIMONIAL]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + UPDATE_TESTIMONIAL_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_TESTIMONIAL]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "DELETE",
                url: rootState.apiBaseUrl + DELETE_TESTIMONIAL_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [APPROVE_TESTIMONIAL]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + APPROVE_TESTIMONIAL_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DECLINE_TESTIMONIAL]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + DECLINE_TESTIMONIAL_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    },
}

export default testimonials;
<template>
  <div class="footer">
    <div class="footer-contents">
        <div class="logo-descript">
            <router-link to="/">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="" draggable="false">
                </div>
            </router-link>
            <p class="descript"><i>~ Ignite your online identity with cornerstone digital assets. Build your presence with superb designs and functionality. ~</i></p>
            <div class="social-icons">
                <a :href="$store.state.company.facebook" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.5 22.5" version="1.1"><g id="surface1726"><path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 11 2.199219 C 6.140625 2.199219 2.199219 6.140625 2.199219 11 C 2.199219 15.410156 5.449219 19.054688 9.683594 19.691406 L 9.683594 13.332031 L 7.507812 13.332031 L 7.507812 11.019531 L 9.683594 11.019531 L 9.683594 9.480469 C 9.683594 6.929688 10.925781 5.8125 13.042969 5.8125 C 14.058594 5.8125 14.59375 5.886719 14.847656 5.921875 L 14.847656 7.941406 L 13.402344 7.941406 C 12.503906 7.941406 12.191406 8.792969 12.191406 9.753906 L 12.191406 11.019531 L 14.824219 11.019531 L 14.46875 13.332031 L 12.191406 13.332031 L 12.191406 19.710938 C 16.484375 19.128906 19.800781 15.453125 19.800781 11 C 19.800781 6.140625 15.859375 2.199219 11 2.199219 Z M 11 2.199219 "/></g></svg>
                </a>
                <a :href="$store.state.company.instagram" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.5 22.5" version="1.1"><g id="surface2498"><path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 13.933594 11 C 13.933594 12.621094 12.621094 13.933594 11 13.933594 C 9.378906 13.933594 8.066406 12.621094 8.066406 11 C 8.066406 9.378906 9.378906 8.066406 11 8.066406 C 12.621094 8.066406 13.933594 9.378906 13.933594 11 Z M 13.933594 11 "/><path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 14.664062 2.199219 L 7.332031 2.199219 C 4.503906 2.199219 2.199219 4.503906 2.199219 7.335938 L 2.199219 14.667969 C 2.199219 17.496094 4.503906 19.800781 7.335938 19.800781 L 14.667969 19.800781 C 17.496094 19.800781 19.800781 17.496094 19.800781 14.664062 L 19.800781 7.332031 C 19.800781 4.503906 17.496094 2.199219 14.664062 2.199219 Z M 11 15.398438 C 8.574219 15.398438 6.601562 13.425781 6.601562 11 C 6.601562 8.574219 8.574219 6.601562 11 6.601562 C 13.425781 6.601562 15.398438 8.574219 15.398438 11 C 15.398438 13.425781 13.425781 15.398438 11 15.398438 Z M 16.132812 6.601562 C 15.730469 6.601562 15.398438 6.269531 15.398438 5.867188 C 15.398438 5.460938 15.730469 5.132812 16.132812 5.132812 C 16.539062 5.132812 16.867188 5.460938 16.867188 5.867188 C 16.867188 6.269531 16.539062 6.601562 16.132812 6.601562 Z M 16.132812 6.601562 "/></g></svg>
                </a>
                <a :href="$store.state.company.linkedin" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.5 22.5" version="1.1"><g id="surface2620"><path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 17.601562 2.933594 L 4.398438 2.933594 C 3.589844 2.933594 2.933594 3.589844 2.933594 4.398438 L 2.933594 17.601562 C 2.933594 18.410156 3.589844 19.066406 4.398438 19.066406 L 17.601562 19.066406 C 18.410156 19.066406 19.066406 18.410156 19.066406 17.601562 L 19.066406 4.398438 C 19.066406 3.589844 18.410156 2.933594 17.601562 2.933594 Z M 8.03125 16.132812 L 5.871094 16.132812 L 5.871094 9.171875 L 8.03125 9.171875 Z M 6.929688 8.175781 C 6.230469 8.175781 5.667969 7.613281 5.667969 6.917969 C 5.667969 6.21875 6.234375 5.65625 6.929688 5.65625 C 7.625 5.65625 8.191406 6.222656 8.191406 6.917969 C 8.191406 7.613281 7.625 8.175781 6.929688 8.175781 Z M 16.136719 16.132812 L 13.972656 16.132812 L 13.972656 12.75 C 13.972656 11.941406 13.960938 10.902344 12.851562 10.902344 C 11.722656 10.902344 11.550781 11.78125 11.550781 12.691406 L 11.550781 16.132812 L 9.390625 16.132812 L 9.390625 9.171875 L 11.464844 9.171875 L 11.464844 10.125 L 11.496094 10.125 C 11.78125 9.578125 12.488281 9 13.539062 9 C 15.730469 9 16.136719 10.441406 16.136719 12.316406 Z M 16.136719 16.132812 "/></g></svg>
                </a>
                <a :href="$store.state.company.twitter" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" class="r-1nao33i r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                </a>
            </div>
        </div>
        <div class="column services">
            <h3>Services</h3>
            <label>> Software development</label>
            <label>> Hosting</label>
            <label>> Digital Marketing</label>
            <label>> IT support services</label>
        </div>
        <div class="column usefulpages">
            <h3>Useful pages</h3>
            <router-link to="/about">About</router-link>
            <router-link to="/services">Services</router-link>
            <router-link to="/hosting">Web Hosting</router-link>
            <router-link to="/contact">Contact</router-link>
        </div>
        <div class="column contact">
            <h3>Contact</h3>
            <label>Phone : {{ $store.state.company.phone }}</label>
            <label>Email : {{ $store.state.company.email }}</label>
            <label>Address : {{ $store.state.company.address }}</label>
        </div>
    </div>
    <div class="copyrights">
        <p>© {{ new Date().getFullYear() }} Ireme Tech. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.footer {
    width: 100%;
    padding: 30px 0 0;
    .footer-contents{
        display: grid;
        column-gap: 50px;
        grid-template-columns: 1.5fr 1fr 1fr 1fr;
        padding: 0 0 30px 0;
        @media (max-width: 991px) {
            row-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 620px) {
            grid-template-columns: 1fr;
        }
        .logo-descript{
            .logo{
                width: 170px;
                height: 50px;
                object-fit: contain;
                position: relative;
                @media (max-width: 620px) {
                    margin: 0 auto;
                }
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
            .descript{
                margin: 30px 0 0 0;
                font-size: 0.9rem;
                color: $realblack;
                @media (max-width: 620px) {
                    text-align: center;
                }
            }

            .social-icons{
                display: flex;
                column-gap: 10px;
                margin: 15px 0 0 0;
                @media(max-width: 620px){
                    justify-content: center;
                    column-gap: 40px;
                }
                a{
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;
                    svg{
                        position: absolute;
                        fill: $realblack;
                        width: 80%;
                        height: 80%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }
        }
        .column{
            h3{
                font-size: 1rem;
                font-weight: 500;
                color: $darkorange;
                margin: 0 0 20px 0;
            }
            label,a{
                text-decoration: none;
                display: block;
                color: $realblack;
                font-size: 0.9rem;
                margin: 0 0 10px 0;
            }
            a{
                &:hover{
                    color: $darkorange;
                }
            }
        }

    }
    .copyrights{
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $grey;
        p{
            font-size: 0.8rem;
            color: $realblack;

        }
    }
}
</style>
<template>
    <SiteLoader v-if="loading" />
    <div class="our-services" v-if="availableServices.length > 0">
        <h2 class="heading"><span>Discover</span> Our Services.</h2>
        <p class="sub-heading">Savings Meets Quality in Our Hosting Plans.</p>
        <div class="services">
            <div class="service" v-for="service in availableServices" :key="service.id">
                <div class="icon">
                    <!-- <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f4961c"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 7L4 12L9 17" stroke="#f4961c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 7L20 12L15 17" stroke="#f4961c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> -->
                    <img :src="$file(service.image)" :alt="service.name">
                </div>
                <div class="details">
                    <h2 class="title">{{ service.name }}</h2>
                    <p class="description">{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services',
    computed: { availableServices(){ return this.services.filter(service => service.visibility == 'public') } },
    data(){
        return{
            services : [],
            loading: false
        }
    },
    mounted(){
        this.$getAllServices()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.our-services {
    padding: 30px 0;
    margin: 30px 0;
    width: 100%;
    .heading{
        font-size: 1.5rem;
        margin: 0 0 5px 0;
        span{
            color: $orange;
        }
    }
    .sub-heading{
        font-size: 0.9rem;
        color: $realblack;
        margin: 0 0 30px 0;
    }
    .services{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 30px;
        @media (max-width: 1200px){
            grid-template-columns: repeat(2,1fr);
        }
        @media (max-width: 800px){
            grid-template-columns: repeat(1,1fr);
        }
        .service{
            background: $white;
            margin: 0 0 30px 0;
            padding: 30px;
            border-radius: 10px;
            // box-shadow: 0px 0px 10px 0px #0000001a;
            border: 1px solid #7171711b;
            text-align: center;
            display: flex;
            align-items: center;
            @media (max-width: 520px){
                flex-direction: column;
            }
            .icon{
                padding: 10px;
                background: $white;
                flex-shrink: 0;
                width: 90px;
                height: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                @media (max-width: 520px){
                    width: 70px;
                    height: 70px;
                }
                // border: 1px solid #7171711b;
                img,svg{
                    width: 80%;
                    height: 80%;
                    object-fit: contain;
                }
            }
            .title{
                font-size: 1.1rem;
                font-weight: 500;
                margin: 10px 0 5px 0;
            }
            .description{
                font-size: 0.8rem;
                color: $realblack;
            }
        }
    }
}
</style>
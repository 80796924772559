<template>
<div class="mainContainer">
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-contact.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1>Contact <span>us</span>.</h1>
                <p>Connecting You to Solutions.</p>
            </div>
        </div>
    </div>
    <div class="contact-section">
        <div class="addresses">
            <div class="address">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M12 3A9 9 0 1 0 12 21A9 9 0 1 0 12 3Z" opacity=".3"/><path d="M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2L12,2z"/><path d="M9.9,9.9L7,17l7.1-2.9L17,7L9.9,9.9z M12,13.1c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S12.6,13.1,12,13.1z"/></svg>
                </div>
                <div class="description">
                    <h1>Address</h1>
                    <label>{{ $store.state.company.address }}</label>
                </div>
            </div>
            <div class="address">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M6 5L6 8 4 10 2 9zM5 15L5 21 19 21 19 15 14 11 10 11z" opacity=".3"/><path d="M20,14.961c0-0.608-0.276-1.182-0.751-1.562L15,10H9l-4.249,3.399C4.276,13.778,4,14.353,4,14.961V22h16V14.961z M6,20 v-5.039L9.701,12h4.597L18,14.961V20H6z"/><path d="M17.146,4.57c0.826,0.254,1.624,0.592,2.385,1.011l-0.436,0.648c-0.297-0.169-0.553-0.301-0.773-0.398 c-0.277-0.123-0.608-0.249-0.991-0.378L17.146,4.57 M5.287,6.918l0.177,0.868C5.165,8.043,4.91,8.283,4.702,8.499 C4.533,8.674,4.347,8.891,4.135,9.16L3.488,8.725C4.026,8.063,4.63,7.458,5.287,6.918 M13.333,2c-0.931,0-1.863,0.087-2.782,0.262 C6.771,3.023,3.35,5.253,1.153,8.52C0.828,9.003,1.074,9.512,1.389,9.724l2.526,1.698c0.149,0.1,0.318,0.149,0.485,0.149 c0.056,0,0.113-0.005,0.168-0.016c0.211-0.041,0.407-0.161,0.541-0.35c0.316-0.446,0.726-0.999,1.032-1.317 c0.331-0.344,0.782-0.724,1.107-0.985c0.255-0.205,0.372-0.534,0.31-0.855l-0.49-2.41c1.309-0.706,2.568-1.133,3.81-1.376 c0.012-0.002,0.025-0.005,0.037-0.007c0.012-0.002,0.025-0.005,0.037-0.007C11.756,4.089,12.595,4,13.487,4 C13.975,4,14.478,4.027,15,4.083l0.506,2.407c0.064,0.321,0.298,0.581,0.611,0.675c0.399,0.12,0.958,0.3,1.395,0.494 c0.403,0.178,0.992,0.536,1.452,0.83c0.145,0.092,0.307,0.137,0.466,0.137c0.056,0,0.112-0.005,0.167-0.016 c0.218-0.043,0.421-0.17,0.555-0.369l1.698-2.526C22.064,5.4,22.1,4.836,21.615,4.512C19.141,2.848,16.24,2,13.333,2L13.333,2z"/><path d="M9 8H11V11H9zM13 8H15V11H13zM3 20H21V22H3zM12 14A1 1 0 1 0 12 16 1 1 0 1 0 12 14zM15 14A1 1 0 1 0 15 16 1 1 0 1 0 15 14zM9 14A1 1 0 1 0 9 16 1 1 0 1 0 9 14zM12 17A1 1 0 1 0 12 19 1 1 0 1 0 12 17zM15 17A1 1 0 1 0 15 19 1 1 0 1 0 15 17zM9 17A1 1 0 1 0 9 19 1 1 0 1 0 9 17z"/><path d="M16 3L17 6 19 7 21 5z" opacity=".3"/></svg>
                </div>
                <div class="description">
                    <h1>Phone</h1>
                    <label>{{ $store.state.company.phone }}</label>
                </div>
            </div>
            <div class="address">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M2,6v12c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2V6c0-1.105-0.895-2-2-2H4C2.895,4,2,4.895,2,6z M20,18H4V6h16V18z"/><path d="M3 8.376L12 14 21 8.376 21 6.376 12 12 3 6.376z"/><path d="M21 7L12 13 3 7 3 19 21 19z" opacity=".3"/></svg>
                </div>
                <div class="description">
                    <h1>Email</h1>
                    <label>{{ $store.state.company.email }}</label>
                </div>
            </div>
            <div class="address">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M14.6 16L11 12.4 11 7 13 7 13 11.6 16 14.6z"/><path d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v14C21,20.1,20.1,21,19,21z M5,5v14h14l0-14H5z"/><path d="M19,20H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14 C20,19.6,19.6,20,19,20z" opacity=".3"/></svg>
                </div>    
                <div class="description">
                    <h1>Business Hours</h1>
                    <label>{{ $store.state.company.working_hours }}</label>
                </div>
            </div>
        </div>
        <form class="form" @submit.prevent="submitForm()">
            <h2>Your Questions, Our Answers.</h2>
            <p>Get in touch with us for inquiries, assistance, or just to say hello. We're here to help and eager to hear from you!</p>
            <label>Names</label>
            <input type="text" v-model="form.names" placeholder="Enter your names" required>
            <div class="flex">
                <div class="input">
                    <label>Email</label>
                    <input type="email" v-model="form.email" placeholder="Enter your email" required>
                </div>
                <div class="input">
                    <label>Phone</label>
                    <input type="text" v-model="form.phone" placeholder="Enter your phone" required>
                </div>
            </div>
            <label>Subject</label>
            <input type="text" v-model="form.subject" placeholder="Enter subject" required>
            <label>Message</label>
            <textarea placeholder="Enter your email" v-model="form.message" rows="5"  required></textarea>
            <button class="btn">
                Send Message
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><path d="M39.175,10.016c1.687,0,2.131,1.276,1.632,4.272c-0.571,3.426-2.216,14.769-3.528,21.83 c-0.502,2.702-1.407,3.867-2.724,3.867c-0.724,0-1.572-0.352-2.546-0.995c-1.32-0.872-7.984-5.279-9.431-6.314 c-1.32-0.943-3.141-2.078-0.857-4.312c0.813-0.796,6.14-5.883,10.29-9.842c0.443-0.423,0.072-1.068-0.42-1.068 c-0.112,0-0.231,0.034-0.347,0.111c-5.594,3.71-13.351,8.859-14.338,9.53c-0.987,0.67-1.949,1.1-3.231,1.1 c-0.655,0-1.394-0.112-2.263-0.362c-1.943-0.558-3.84-1.223-4.579-1.477c-2.845-0.976-2.17-2.241,0.593-3.457 c11.078-4.873,25.413-10.815,27.392-11.637C36.746,10.461,38.178,10.016,39.175,10.016 M39.175,7.016L39.175,7.016 c-1.368,0-3.015,0.441-5.506,1.474L33.37,8.614C22.735,13.03,13.092,17.128,6.218,20.152c-1.074,0.473-4.341,1.91-4.214,4.916 c0.054,1.297,0.768,3.065,3.856,4.124l0.228,0.078c0.862,0.297,2.657,0.916,4.497,1.445c1.12,0.322,2.132,0.478,3.091,0.478 c1.664,0,2.953-0.475,3.961-1.028c-0.005,0.168-0.001,0.337,0.012,0.507c0.182,2.312,1.97,3.58,3.038,4.338l0.149,0.106 c1.577,1.128,8.714,5.843,9.522,6.376c1.521,1.004,2.894,1.491,4.199,1.491c2.052,0,4.703-1.096,5.673-6.318 c0.921-4.953,1.985-11.872,2.762-16.924c0.331-2.156,0.603-3.924,0.776-4.961c0.349-2.094,0.509-4.466-0.948-6.185 C42.208,7.875,41.08,7.016,39.175,7.016L39.175,7.016z"/></svg>
            </button>
        </form>
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
export default {
    name: 'Contact',
    data(){
        return {
            form: {
                names: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            }
        }
    },
    methods: {
        submitForm(){
            this.$store.dispatch('CREATE_CONTACT', this.form)
                .then(res => {
                    if(res.data.message == 'Message sent successfully'){
                        this.$notify({
                            text: res.data.message,
                            type: 'success'
                        })
                        this.form = {
                            names: '',
                            email: '',
                            phone: '',
                            subject: '',
                            message: ''
                        }
                    }else{
                        this.$notify({
                            text: res.data.message,
                            type: 'error'
                        })
                    }
                })
                .catch(err => {
                    this.$notify({
                        text: 'Something went wrong',
                        type: 'error'
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.contact-section{
    width: 100%;
    padding: 10px 0;
    display: flex;
    margin: 0 0 50px 0;
    @media (max-width: 991px){
        flex-direction: column-reverse;
    }
    .addresses{
        padding: 0px 50px;
        border-radius: 15px;
        width: 30%;
        @media (max-width: 991px){
            width: 100%;
        }
        .address{
            display: flex;
            column-gap: 30px;
            padding: 20px 0;
            border-bottom: 1px solid $grey;
            &:last-child{
                border-bottom: none;
            }
            @media (max-width: 991px){
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            .icon{
                width: 40px;
                height: 40px;
                position: relative;
                flex-shrink: 0;
                svg{
                    width: 100%;
                    height: 100%;
                    fill: $orange;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .description{
                h1{
                    font-size: 1rem;
                    font-weight: 500;
                    color: $realblack;
                }
                label{
                    font-size: 0.9rem;
                    color: #696969;
                }
            }
        }
    }
    .form{
        width: 70%;
        padding: 10px 50px;
        @media (max-width: 991px){
            width: 100%;
            margin: 30px 0;
        }
        @media (max-width: 666px){
            padding: 10px 15px;
        }
        h2{
            font-size: 1.5rem;
            font-weight: 500;
            color: $realblack;
            margin: 0 0 5px;
        }
        p{
            font-size: 0.9rem;
            color: #696969;
            margin: 0 0 10px;
        }
        label{
            font-size: 0.9rem;
            color: $realblack;
        }
        input, textarea{
            width: 100%;
            padding: 10px 20px;
            border: 1px solid $grey;
            border-radius: 5px;
            margin: 10px 0;
            outline: none;
            &:focus{
                border: 1px solid $orange;
            }
        }
        .flex{
            display: flex;
            column-gap: 10px;
            @media (max-width: 666px){
                flex-direction: column;
            }
            .input{
                width: 50%;
                @media (max-width: 666px){
                    width: 100%;
                }
            }
        }
        .btn{
            outline: none;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            font-size: 0.8rem;
            column-gap: 10px;
            background: $darkorange;
            border: none; 
            justify-content: center;
            color: #fff;
            width: 100%;
            white-space: nowrap;
            svg{
                fill: #fff;
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>
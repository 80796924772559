import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixins/mixin'

// components
import Navbar from '@/components/Site/Navbar.vue'
import Footer from '@/components/Site/Footer.vue'
import Subscribe from '@/components/Site/Subscribe.vue'
import Testimonials from '@/components/Site/Testimonials.vue'
import Projects from '@/components/Site/Projects.vue'
import Pricing from '@/components/Site/Pricing.vue'
import Services from '@/components/Site/Services.vue'
import Map from '@/components/Site/Map.vue'
import Team from '@/components/Site/Team.vue'
import Blog from '@/components/Site/Blog.vue'
import Sidebar from '@/components/Dashboard/Sidebar.vue'
import Topbar from '@/components/Dashboard/Topbar.vue'
import Panelbar from '@/components/Dashboard/Panelbar.vue'
import Uploader from '@/components/Dashboard/Uploader.vue'
import Loader from '@/components/Dashboard/Loader.vue'
import SiteLoader from '@/components/Site/Loader.vue'
import PanelLoader from '@/components/Dashboard/PanelLoader.vue'

// libraries
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
// import VueEditor from 'vue3-editor'

const app = createApp(App)
app.use(store)
app.component('Navbar', Navbar)
app.component('Footer', Footer)
app.component('Subscribe', Subscribe)
app.component('Testimonials', Testimonials)
app.component('Projects', Projects)
app.component('Pricing', Pricing)
app.component('Services', Services)
app.component('Map', Map)
app.component('Team', Team)
app.component('Blog', Blog)
app.component('Sidebar', Sidebar)
app.component('Topbar', Topbar)
app.component('Panelbar', Panelbar)
app.component('Uploader', Uploader)
app.component('Loader', Loader)
app.component('SiteLoader', SiteLoader)
app.component('PanelLoader', PanelLoader)
// app.component('VueEditor', VueEditor)
app.use(Notifications)
app.use(VueAxios, axios)
app.mixin(mixin)
app.use(router)
app.mount('#app')

import axios from 'axios'
import { GET_ALL_SERVICES, CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../actions/services';
import { GET_ALL_SERVICES_URL, CREATE_SERVICE_URL, UPDATE_SERVICE_URL, DELETE_SERVICE_URL } from '../variables';

const services = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_SERVICES]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_SERVICES_URL
            })
        },
        [CREATE_SERVICE]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CREATE_SERVICE_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_SERVICE]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + UPDATE_SERVICE_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_SERVICE]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "DELETE",
                url: rootState.apiBaseUrl + DELETE_SERVICE_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
    },
}

export default services;
<template>
  <div class="dashboardContent">
    <div class="content">
        <Panelbar />
        <PanelLoader v-if="loading" />
        <ProjectProposal v-if="modal" :current="form" :modalform="modalform" @close="closeModal" @refresh="getprojects" />
        <div class="detailed-content">
            <div class="tabs">
                <label class="tab" @click="tab = 'myprojects'" :class="{ active: tab == 'myprojects' }">My Projects</label>
                <label class="tab" @click="tab = 'hosting'" :class="{ active: tab == 'hosting' }">Hosting</label>
                <label class="tab" @click="tab = 'profile'" :class="{ active: tab == 'profile' }">Account</label>
            </div>
            <MyProjects @toggleModal="openModal" @getprojects="getprojects" @viewProject="openProject" :projects="projects" v-if="tab == 'myprojects'" />
            <Hosting  @toggleModal="openModal" @getprojects="getprojects" @viewProject="openProject" :hostings="hostings" v-if="tab == 'hosting'" />
            <Project :projectId="projectId" v-if="tab == 'project'" />
            <Account v-if="tab == 'profile'" />
        </div>
    </div>
  </div>
</template>

<script>
import Account from './components/Account.vue'
import MyProjects from './components/MyProjects.vue'
import Project from './components/Project.vue'
import Hosting from './components/Hosting.vue'
import ProjectProposal from './components/ProjectProposal.vue'
export default {
    name: "UserPanel",
    components: { Account, MyProjects, Hosting, ProjectProposal, Project },
    data() {
        return {
            tab: 'myprojects',
            loading: false,
            modalform: { type: null, form: null },
            modal: false,
            projects: [],
            hostings: [],
            form: {
                name: '',
                domain: '',
                description: '',
                development_cost: '',
                status: '',
                service_id: '',
                hosting_id: '',
                project_type: 'personal',

            }
        }
    },
    methods: {
        getMyProjects(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_PROJECTS')
                .then(res => {
                    this.projects = res.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        },
        openProject(project){
            this.projectId = project.id;
            this.tab = 'project';
        },
        getMyHosting(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_MY_HOSTING').then(res => {
                this.hostings = res.data;
                this.loading = false;
            }).catch(err => {
                this.loading = false;
            })
        },
        checkAuth() {
            if (!this.$loggedUser()) {
                this.$router.push('/signin')
            }
        },
        openModal(action, content, modalform){
            this.modal = true;
            this.modalform = { type: action, form: modalform }
            action != 'add' ? { ...this.form } = content : ''
        },
        closeModal(){
            this.modal = false;
            this.modalform = { type: null, form: null }
            this.form = {
                name: '',
                domain: '',
                description: '',
                budget: '',
                status: '',
                service_id: '',
                hosting_id: ''
            }
        },
        getprojects(modalform){
            modalform == 'hostings' ? this.getMyHosting() : this.getMyProjects()
        },
    },
    mounted() {
        this.checkAuth()
    }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/main.scss";
.detailed-content{
    padding: 20px;
    .locator{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 30px;
        .locator-heading{
            font-size: 1.1rem;
            font-weight: 500;
        }
        button{
            all: unset;
            color: #905b06;
            background-color: #f5a62361;
            font-size: 0.8rem;
            padding: 7px 15px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            display: flex;
            align-items: center;
            column-gap: 15px;
            svg{
                fill: #905b06;
                width: 17px;
                height: 17px;
            }
        }
    }
    .tabs{
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 20px 0px;
        column-gap: 30px;
        .tab{
            font-size: 0.8rem;
            font-weight: 500;
            color: #333;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            &:hover{
                background-color: #f1f1f1;
            }
            &.active{
                background-color: #f5a62361;
                color: #905b06;
            }
        }
    }
    .label{
        font-size: 0.9rem;
        font-weight: 500;
        margin: 0 0 10px;
    }
    .view{
        width: 800px;
        margin: auto;
        &.project{
            width: 80%;
        }
        .heading{
            font-size: 1rem;
            font-weight: 500;
            margin: 0 0 30px;
        }
        .no-data{
            font-size: 0.8rem;
            font-weight: 400;
            display: block;
            text-align: center;
            color: #333333f0;
            margin: 20px auto;
        }
        .listings{
            overflow-y: auto;
            .headings{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                border-bottom: 1px solid #f7f7f7;
                h3{
                    font-size: 0.81rem;
                    font-weight: 500;
                    color: #333333c2;
                    &:last-child{
                        text-align: right;
                    }
                }
            }
            .list{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                justify-content: space-between;
                padding: 30px 0;
                border-bottom: 1px solid #f7f7f7;
                label{
                    font-size: 0.72rem;
                    font-weight: 500;
                    color: #333333f0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.status{
                        color: #f5a623;
                    }
                }
                div{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    column-gap: 10px;
                    button{
                        all: unset;
                        background-color: #f5a62361;
                        color: #905b06;
                        font-size: 0.8rem;
                        padding: 5px 15px;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: .6s;
                        display: flex;
                        align-items: center;
                        column-gap: 15px;
                        width: 15px;
                        height: 15px;
                        position: relative;
                        svg{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                            height: 70%;
                        }
                        &.view{
                            background-color: #f5a623;
                        }
                        &.edit{
                            background-color: #23a1f5;
                        }
                        &.delete{
                            background-color: #f52358;
                        }
                    }
                }
            }
        }
    }
    .wrapper-box{
        border: 1px solid #f7f7f7;
        padding: 15px 20px;
        margin: 0 0 20px;
        border-radius: 7px;
        width: 100%;
        .flex-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .profile{
                display: flex;
                align-items: center;
                column-gap: 20px;
                max-width: 400px;
                .img{
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 0;
                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #00000061;
                        opacity: 0;
                        transition: .6s;
                    }
                    svg{
                        opacity: 0;
                        transition: .6s;
                        pointer-events: none;
                        position: absolute;
                        cursor: pointer;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 40%;
                        height: 40%;
                    }
                    &:hover{
                        &::before{
                            opacity: 1;
                        }
                        svg{
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        &.logo{
                            object-fit: contain;
                        }
                    }
                }
                .info{
                    .names{
                        font-size: 0.9rem;
                        font-weight: 500;
                        margin: 0 0 2px;
                    }
                    .role{
                        font-size: 0.75rem;
                        color: #7d7d7d;
                    }
                }
            }
            .edit{
                all: unset;
                background-color: #f5a62361;
                color: #905b06;
                font-size: 0.8rem;
                padding: 5px 15px;
                border-radius: 5px;
                cursor: pointer;
                transition: .6s;
                display: flex;
                align-items: center;
                column-gap: 15px;
                svg{
                    width: 15px;
                    height: 15px;
                }
            }
            .info{
                width: 500px;
                .flex{
                    display: flex;
                    column-gap: 30px;
                    &:not(:last-child){
                        margin: 0 0 20px;
                    }
                    div{
                        width: 50%;
                        overflow: hidden;
                        label{
                            font-size: 0.9rem;
                            font-weight: 500;
                            display: block;
                            margin: 0 0 4px;
                        }
                        p{
                            font-size: 0.8rem;
                            color: #7d7d7d;
                            &.description{
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &.preview{
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                column-gap: 10px;
                                svg{
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                        input{
                            width: 100%;
                            border: 1px solid #dddddd;
                            border-radius: 4px;
                            padding: 7px 15px;
                            font-size: 0.8rem;
                            color: #333;
                            &:focus{
                                outline: none;
                                border-color: #f5a623;
                            }
                        }
                        a{
                            margin: 10px 0 0;
                            text-decoration: none;
                            font-size: 0.75rem;
                            color: #7d7d7d;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            .security{
                width: 100%;
                .flex{
                    display: flex;
                    column-gap: 30px;
                    width: 100%;
                    &:not(:last-child){
                        margin: 0 0 20px;
                    }
                    div{
                        width: 100%;
                        label{
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: #333;
                            display: block;
                            margin: 0 0 5px;
                        }
                        input{
                            width: 100%;
                            border: 1px solid #dddddd;
                            border-radius: 4px;
                            padding: 7px 15px;
                            font-size: 0.8rem;
                            color: #333;
                            &:focus{
                                outline: none;
                                border-color: #f5a623;
                            }
                        }
                    }
                }
                .btn-submit{
                    all: unset;
                    background-color: #f5a62361;
                    color: #905b06;
                    font-size: 0.8rem;
                    padding: 7px 0;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: .6s;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    column-gap: 15px;
                    justify-content: center;
                    svg{
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
</style>
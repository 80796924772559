<template>
    <SiteLoader v-if="loading" />
    <div class="blog" v-if="availableArticles.length > 0">
    <h2 class="heading">Discover Our <span>Insights</span>.</h2>
    <p class="sub-heading">From Algorithms to Zeros and Ones.</p>
        <div class="articles" v-if="!count">
            <div class="article" v-for="article in availableArticles" :key="article.id">
                <div class="cover">
                    <img :src="$file(article.image)" alt="article">
                </div>
                <div class="content">
                    <router-link :to="`blog/${article.slug}`" class="title">{{ article.title }}</router-link>
                    <p class="description">{{ article.content }}</p>
                    <label class="category">{{ article.category }}</label>
                </div>
                <div class="date">
                    <span>{{ article.date }}</span>
                </div>
            </div>
        </div>
        <div class="articles" v-else>
            <div class="article" v-for="article in availableArticles.slice(0,3)" :key="article.id">
                <div class="cover">
                    <img :src="$file(article.image)" alt="article">
                </div>
                <div class="content">
                    <router-link :to="`blog/${article.slug}`" class="title">{{ article.title }}</router-link>
                    <p class="description">{{ article.content }}</p>
                    <label class="category">{{ article.category }}</label>
                </div>
                <div class="date">
                    <span>{{ article.date }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Articles',
    props: {
        count: {
            type: Number,
            default: null
        }
    },
    computed: { availableArticles(){ return this.articles.filter(article => article.visibility == 'public') } },
    data(){
        return{
            loading: false,
            articles: []
        }
    },
    mounted(){
        this.$allArticles()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.blog{
    margin: 40px 0;
    .heading{
        font-size: 1.5rem;
        margin: 10px 0 5px 0;
        span{
            color: $orange;
        }
    }
    .sub-heading{
        font-size: 0.9rem;
        color: $realblack;
        margin: 0 0 30px 0;
    }
    .articles{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 50px;
        row-gap: 30px;
        @media (max-width: 991px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 670px) {
            grid-template-columns: 1fr;
        }
        .article{
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            height: 400px;
            position: relative;
            color: #fff;
            .cover{
                width: 100%;
                overflow: hidden;
                height: 100%;
                position: absolute;
                &::before{
                    content: '';
                    z-index: 1;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #00000077;
                }
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content{
                padding: 30px;
                z-index: 1;
                position: absolute;
                bottom: 0;
                left: 0;
                .title{
                    font-size: 1.7rem;
                    margin: 0 0 10px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-decoration: none;
                    color: #fff;
                    transition: .4s;
                    font-weight: 600;
                    &:hover{
                        color: $orange;
                    }
                }
                .description{
                    font-size: 0.9rem;
                    margin: 0 0 10px 0;
                    line-height: 1.5rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    transition: .4s;
                }
                .category{
                    font-size: 0.8rem;
                    background: #ffffff6e;
                    padding: 5px 10px;
                    color: #fff;
                    border-radius: 5px;
                    display: inline-block;
                    margin: 10px 0 0;
                }
            }
            .date{
                position: absolute;
                top: 30px;
                right: 30px;
                z-index: 1;
            }
        }
    }
}
</style>
<template>
<div class="overlay">
    <form class="form" @submit.prevent="submitForm()">
        <div class="top">
            <h3 class="title">{{ type }} Project Document</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="form && type == 'add'">
            <label>Choose file type</label>
            <select v-model="form.file_type" required>
                <option value="">Select file type</option>
                <option value="proposal">Proposal</option>
                <option value="proforma">Proforma</option>
                <option value="agreement">Agreement</option>
                <option value="invoice">Invoice</option>
                <option value="receipt">Receipt</option>
                <option value="other">Other</option>
            </select>
            <label v-if="form.file_type">Attach File</label>
            <Uploader
                v-if="form.file_type"
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="(data) =>{ data }"
                :values="form"
                @close="close"
            />
        </div>
        <label class="warn-text" v-if="type == 'delete'">Are you sure you want to detete this document ?</label>
        <button v-if="type == 'delete'">Delete Document</button>
    </form>
</div>
</template>

<script>
export default {
    name: "DocumentModal",
    props: {
        current: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {
                file_type: null,
                project_id: null,
                user_type: 'admin',
                user_id: this.$loggedAdmin().id
            },
        }
    },
    methods:{
        close(){
            this.$emit("refresh")
            this.$emit("close")
        },
        handelform(){
            if(this.type == 'add'){
                this.form.project_id = this.current
            } else if(this.type == 'delete'){
                this.form.id = this.current
            }
        },
        submitForm(){
            this.loading = true
            if(this.type == 'delete'){
                this.$store.dispatch('DELETE_FILE', this.form.id)
                .then((res) => {
                    if(res.data.status == 'success'){
                        this.$notify({
                            type: "success",
                            text: res.data.message,
                        });
                        this.$emit("refresh")
                        this.$emit("close")
                    } else {
                        this.$notify({
                            type: "error",
                            text: res.data.message,
                        });
                    }
                })
            }
        }
    },
    mounted() {
        this.handelform()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
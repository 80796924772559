<template>
<div class="mainContainer">
    <Navbar />
    <SiteLoader v-if="loading" />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-blog.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1><span>The</span> article<span>.</span></h1>
                <p>Unlocking the Secrets of Deep Learning</p>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <div class="article" v-if="article">
            <div class="cover">
                <img :src="$file(article.image)" alt="article">
            </div>
            <div class="content">
                <h2 class="heading">{{ article.title }}</h2>
                <div class="date-category">
                    <label class="date">{{ article.date }}</label>
                    <label class="category" v-if="article.category"> - {{ article.category }}</label>
                </div>
                <div class="description">
                    <p>{{ article.content }}</p>
                </div>
            </div>
            <div class="comments">
                <h2 class="heading">Comments</h2>
                <form @submit.prevent="addComment()">
                    <textarea v-model="comment" placeholder="Write something" required></textarea>
                    <button>Post Comment</button>
                </form>
                <label class="zero" v-if="article.comments.length < 1">Be the first to comment!</label>
                <div class="comment" v-for="comment in article.comments" :key="comment.id">
                    <div class="avatar">
                        <img :src="$file(comment.user.image)" alt="avatar">
                    </div>
                    <div class="commenter-content">
                        <div class="name-date">
                            <label class="name">{{ comment.user.firstname }} {{ comment.user.lastname }}</label> -
                            <label class="date">{{ comment.date }}</label>
                        </div>
                        <div class="comment-content">
                            <p>{{ comment.message }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
export default {
    name: "Article",
    data(){
        return{
            loading: true,
            comment: '',
            article: null
        }
    },
    methods: {
        getArticle(){
            this.loading = true;
            this.$store.dispatch('GET_SINGLE_BLOG', this.$route.params.slug)
                .then(res => {
                    this.article = res.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                })
        },
        addComment(){
            if(this.$loggedUser()){
                this.loading = true;
                let payload = {
                    blog_id: this.article.id,
                    // user_id: this.$loggedUser().id,
                    message: this.comment
                }
                this.$store.dispatch('ADD_BLOG_COMMENT', payload)
                    .then(res => {
                        this.comment = '';
                        this.getArticle();
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                    })
            }else{
                this.$notify({
                    type: 'error',
                    text: 'You need to login to comment',
                })
            }
        }
    },
    mounted(){
        this.getArticle();
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    .cover{
        width: 100%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0 0 20px 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content{
        width: 100%;
        padding: 0 20px;
        .heading{
            font-size: 2rem;
            margin: 0 0 10px 0;
        }
        .date-category{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 10px 0;
            .date{
                font-size: 0.8rem;
                margin: 0 10px 0 0;
            }
            .category{
                font-size: 0.8rem;
                margin: 0 0 0 10px;
            }
        }
        .description{
            p{
                font-size: 0.9rem;
                margin: 0 0 10px 0;
                line-height: 1.8rem;
            }
        }
    }
    .comments{
        width: 100%;
        padding: 0 20px;
        margin: 30px 0;
        .heading{
            font-size: 1.2rem;
            margin: 0 0 20px 0;
        }
        form{
            margin: 0 0 30px 0;
            textarea{
                outline: none;
                width: 100%;
                max-width: 500px;
                height: 150px;
                border-radius: 7px;
                border: 1px solid $grey;
                padding: 20px;
                margin: 0 0 10px 0;
                resize: none;
            }
            button{
                border: none;
                outline: none;
                display: block;
                cursor: pointer;
                text-transform: uppercase;
                border-radius: 7px;
                padding: 8px 20px;
                margin: 0 0 10px 0;
                background: $orange;
                color: $white;
                font-weight: 600;
                cursor: pointer;
                font-size: 0.8rem;
                transition: .6s;
                border: 1px solid $orange;
                &:hover{
                    background: $white;
                    color: $orange;
                }
            }
        }
        .zero{
            font-size: 0.8rem;
            margin: 0 0 20px 0;
        }
        .comment{
            display: flex;
            column-gap: 20px;
            margin: 0 0 20px 0;
            border-bottom: 1px solid $grey;
            .avatar{
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .commenter-content{
                width: 100%;
                max-width: 800px;
                .name-date{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0 0 10px 0;
                    .name{
                        font-weight: 600;
                        font-size: 0.9rem;
                        margin: 0 10px 0 0;
                    }
                    .date{
                        font-size: 0.8rem;
                        margin: 0 0 0 10px;
                    }
                }
                .comment-content{
                    p{
                        font-size: 0.8rem;
                        margin: 0 0 10px 0;
                        line-height: 1.8rem;
                        color: #808080;
                    }
                }
            }
        }
    }
}
</style>
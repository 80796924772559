<template>
    <div class="addressmap">
        <div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=699&amp;height=631&amp;hl=en&amp;q=fairview building&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div></div>
    </div>
</template>

<script>
export default {
    name: "Map"
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.addressmap{
    width: 100%;
    margin: 30px 0;
    overflow: hidden;
    border-radius: 10px;
    height: 400px;
    position: relative;

    .mapouter{
        position:relative;
        text-align:right;
        width:100%;
        height:400px;
    }
    .gmap_canvas {
        overflow: hidden;
        background: none!important;
        width: 100%;
        height: 631px;
    }
    .gmap_iframe {
        height: 631px!important;
    }
}
</style>
<template>
<div class="mainContainer">
    <Review @close="close" v-if="review" />
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/hero-2.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1>Ireme <span>Technologies</span> Ltd.</h1>
                <p>We love Transforming Product Ideas to Digital Realities</p>
                <div class="btns">
                    <router-link to="/services" class="btn">Our Services</router-link>
                    <router-link to="/about" class="btn other">About us</router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <OurStory />
        <Services />
        <Pricing />
        <HappyClients />
        <Testimonials />
        <Blog :count="3" />
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
import OurStory from '@/components/Site/OurStory.vue';
import Review from '@/components/Site/Review.vue';
import HappyClients from '@/components/Site/HappyClients.vue';
export default {
    name: 'Home',
    components: { OurStory, Review, HappyClients },
    data() {
        return {
            review: false,
            loading: false
        }
    },
    watch: {
        $route(to, from) {
            if (to.hash == '#review') {
                this.review = true
            } else {
                this.review = false
            }
        },
    },
    methods: {
        close() {
            this.$router.push('/')
        },
        checkUrl(){
            if (this.$route.hash == '#review') {
                this.review = true
            } else {
                this.review = false
            }
        }
    },
    mounted(){
        this.checkUrl()
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.hero-section{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .overlay{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: #00000085;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .hero-content{
        z-index: 1;
        text-align: center;
        .content{
            color: $white;
            h1{
                font-size: 2.5rem;
                @media (max-width: 580px){
                    font-size: 2rem;
                }
                span{
                    color: $orange;
                }
            }
            p{
                color: $grey;
                @media (max-width: 580px){
                    font-size: 0.9rem;
                }
            }
            .btns{
                margin: 30px 0;
                .btn{
                    border-radius: 7px;
                    margin: 0 10px;
                    text-decoration: none;
                    padding: 10px 20px;
                    font-size: 0.8rem;
                    background: $orange;
                    border: 1px solid $orange;
                    color: $white;
                    transition: .4s;
                    &:hover{
                        background: $orange;
                        color: $white;
                    }
                    &.other{
                        background: transparent;
                        border: 1px solid $white;
                        color: $white;
                        &:hover{
                            background: $white;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
  <SiteLoader v-if="loading" />
  <notifications position="bottom right" />
  <router-view/>
</template>
<script>
export default {
  name: "App",
  data(){
    return {
      loading: false
    }
  },
  methods: {
    companyProfile(){
      this.loading = true
      this.$store.dispatch('GET_ALL_COMPANY_DATA')
          .then((res) => {
            this.$store.state.company = res.data
            this.loading = false
          })
    }
  },
  mounted(){
    this.$store.dispatch('initializeApiBaseUrl')
    this.companyProfile()
  }
};
</script>
<style lang="scss">
@import "./scss/main.scss";
*{
  margin: 0;
  padding: 0;
  font-family: Poppins;
  box-sizing: border-box;
}
</style>
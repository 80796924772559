<template>
<div class="overlay">
    <SiteLoader v-if="loading" />
    <form class="form" @submit.prevent="submitForm()">
        <div class="top">
            <h3 class="title">{{ type }} An Update</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="form && type == 'add' || type == 'edit'">
            <label>Write message</label>
            <textarea v-model="form.message" placeholder="Write somenthing....." rows="5"></textarea>
        </div>
        <label class="warn-text" v-if="type == 'delete'">Are you sure you want to detete this update ?</label>
        <button>{{ type }} An Update</button>
    </form>
</div>
</template>

<script>
export default {
    name: "UpdateModal",
    props: {
        current: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = {...this.current}
        },
        submitForm(){
            this.loading = true
            if(this.type == 'add'){
                this.$store.dispatch('CREATE_FEEDBACK_UPDATE', this.form)
                .then(res => {
                    if(res.data.status == 'success'){
                        this.$notify({
                            type: "success",
                            text: res.data.message
                        });
                        this.loading = false
                        this.$emit("close")
                        this.$emit("refresh")
                    } else {
                        this.$notify({
                            type: "error",
                            text: res.data.message
                        });
                    }
                })
            } else if(this.type == 'edit'){
                this.$store.dispatch('UPDATE_FEEDBACK_UPDATE', this.form)
                .then(res => {
                    this.loading = false
                    if(res.data.status == 'success'){
                        this.$notify({
                            type: "success",
                            text: res.data.message
                        });
                        this.$emit("close")
                        this.$emit("refresh")
                    } else {
                        this.$notify({
                            type: "error",
                            text: res.data.message
                        });
                    }
                })
            } else if(this.type == 'delete'){
                this.$store.dispatch('DELETE_FEEDBACK_UPDATE', this.form.id)
                .then(res => {
                    this.loading = false
                    if(res.data.status == 'success'){
                        this.$notify({
                            type: "success",
                            text: res.data.message
                        });
                        this.$emit("close")
                        this.$emit("refresh")
                    } else {
                        this.$notify({
                            type: "error",
                            text: res.data.message
                        });
                    }
                })
            }
        }
    },
    mounted() {
        this.handelform()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
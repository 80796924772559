<template>
    <SiteLoader v-if="loading" />
    <div class="host-pricing" v-if="availablePlans.length > 0">
        <h2 class="heading">Affordable <span>Hosting,</span>  Exceptional Value!.</h2>
        <p class="sub-heading">Savings Meets Quality in Our Hosting Plans.</p>
        <div class="pricings">
            <div class="pricing" v-for="plan in availablePlans" :key="plan.id">
                <h2 class="title">{{ plan.name }}</h2>
                <div class="price">
                    <h2 class="pricetag">${{ plan.price }}</h2>
                    <p class="label">/ Year</p>
                </div>
                <div class="start-button">Get started</div>
                <div class="details">
                    <ul>
                        <li v-for="(attribute,index) in plan.attributes" :key="index">{{ attribute }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing',
    computed: { availablePlans(){ return this.plans.filter(plan => plan.visibility == 'public') } },
    data(){
        return{
            loading: false,
            plans: []
        }
    },
    mounted(){
        this.$getAllHostingPlans()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.host-pricing {
    padding: 30px 0;
    margin: 30px 0;
    width: 100%;
    .heading{
        font-size: 1.5rem;
        margin: 0 0 5px 0;
        span{
            color: $orange;
        }
    }
    .sub-heading{
        font-size: 0.9rem;
        color: $realblack;
        margin: 0 0 30px 0;
    }
    .pricings{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        overflow: hidden;
        @media (max-width: 1140px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .pricing{
            border-radius: 20px;
            border: 1px solid #7171711b;
            border-radius: 5px;
            overflow: hidden;
            margin: 10px 0;
            background: #f7f8fc;
            height: 500px;
            .title{
                width: 100%;
                text-align: center;
                font-size: 1.3rem;
                font-weight: 600;
                margin: 20px 0 10px 0;
            }
            .price{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin: 30px 0;
                .pricetag{
                    font-size: 1.9rem;
                    margin: 0 0 5px 0;
                }
                .sub-label{
                    font-size: 0.8rem;
                    margin: 0 0 0 5px;
                }
            }
            .start-button{
                width: fit-content;
                margin: auto;
                padding: 7px 20px;
                background: $orange;
                color: $white;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                transition: all .4s;
                &:hover{
                    background: $orange;
                }
            }
            .details{
                margin: 20px 0 0;
                padding: 20px 50px;
                border-radius: 20px 20px 0 0;
                background: $white;
                max-height: 400px;
                height: 100%;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                }
                &::-webkit-scrollbar-thumb {
                    background: $orange; 
                    border-radius: 5px;
                }
                ul{
                    // list-style: none;
                    li{
                        font-size: 0.9rem;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}
</style>
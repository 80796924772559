<template>
<div class="overlay">
    <form class="form" @submit.prevent="submitform()">
        <div class="top">
            <h3 class="title">{{ type }} Member</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="type != 'delete' && form">
            <div class="flex">
                <div class="flex-1">
                    <label>First Name</label>
                    <input type="text" v-model="form.firstname" placeholder="Enter first name" required>
                </div>
                <div class="flex-1">
                    <label>Last Name</label>
                    <input type="text" v-model="form.lastname" placeholder="Enter last name" required>
                </div>
            </div>
            <div class="flex">
                <div class="flex-1">
                    <label>Telephone</label>
                    <input type="text" v-model="form.phone" placeholder="Enter telephone number" required>
                </div>
                <div class="flex-1">
                    <label>Email</label>
                    <input type="email" v-model="form.email" placeholder="Enter email address" required>
                </div>
            </div>
            <div class="flex">
                <div>
                    <label>Position</label>
                    <input type="text" v-model="form.position" placeholder="Enter member position" required>
                </div>
                <div>
                    <label>Address</label>
                    <input type="text" v-model="form.address" placeholder="Enter member address" required>
                </div>
            </div>
            <div class="flex">
                <div class="flex-1">
                    <label>Status</label>
                    <select v-model="form.status" required>
                        <option value="" hidden selected>Select Status</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Part Time">Part Time</option>
                        <option value="Intern">Intern</option>
                        <option value="Casual">Casual</option>
                    </select>
                </div>
                <div class="flex-1">
                    <label>Start Date</label>
                    <input type="date" v-model="form.start_date" required>
                </div>
            </div>
            <div class="flex">
                <div>
                    <label>Facebook</label>
                    <input type="text" v-model="form.facebook" placeholder="Enter facebook link">
                </div>
                <div>
                    <label>Twitter</label>
                    <input type="text" v-model="form.twitter" placeholder="Enter twitter link">
                </div>
            </div>
            <div class="flex">
                <div>
                    <label>LinkedIn</label>
                    <input type="text" v-model="form.linkedin" placeholder="Enter linkedin link">
                </div>
                <div>
                    <label>Instagram</label>
                    <input type="text" v-model="form.instagram" placeholder="Enter intagram link">
                </div>
            </div>
            <label>Visibility</label>
            <select v-model="form.visibility" required>
                <option value="" disabled selected>Select visibility</option>
                <option value="public">Public</option>
                <option value="unlisted">Unlisted</option>
            </select>
            <label>Bio</label>
            <textarea rows="4" v-model="form.bio" placeholder="Enter member bio"></textarea>
            <label>Image</label>
            <Uploader
                v-if="form.image"
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="(data) =>{ form.image = data }"
                :value="form.image"
            />
            <Uploader
                v-else
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="(data) =>{ form.image = data }"
                :value="form.image"
            />
        </div>
        <label class="warn-text" v-else>Are you sure you want to detete this member ?</label>
        <button>{{ type }} Member</button>
    </form>
</div>
</template>

<script>
export default {
    name: "MemberModal",
    props: {
        current: {
            type: Object,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = this.current
        },
        submitform(){
            if(this.type == "add"){
                this.$store.dispatch("CREATE_TEAM_MEMBER", this.form)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    for (const key in err.response.data.errors) {
                        if (errors.hasOwnProperty(key)) {
                            errors[key].forEach(errorMsg => {
                                this.$notify({
                                    text: `${errorMsg}`,
                                    type: 'error'
                                })
                            });
                        }
                    }
                })
            }else if(this.type == "edit"){
                this.$store.dispatch("UPDATE_TEAM_MEMBER", this.form)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    console.log(err)
                    // for (const key in err.response.data.errors) {
                    //     if (errors.hasOwnProperty(key)) {
                    //         errors[key].forEach(errorMsg => {
                    //             this.$notify({
                    //                 text: `${errorMsg}`,
                    //                 type: 'error'
                    //             })
                    //         });
                    //     }
                    // }
                })
            }else if(this.type == "delete"){
                this.$store.dispatch("DELETE_TEAM_MEMBER", this.form.id)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    console.log(err)
                })
            }
        }
    },
    mounted() {
        this.handelform()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
<template>
<div class="overlay">
    <SiteLoader v-if="loading" />
    <form class="form" @submit.prevent="submitform()">
        <div class="top">
            <h3 class="title">{{ modalform.type }} Proposal</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="modalform.type != 'delete' && form && modalform.form == 'projects'">
            <label>Project name</label>
            <input type="text" placeholder="Enter project name" v-model="form.name" required>
            <label>Project service</label>
            <select v-model="form.service_id">
                <option value="" selected hidden>Select Service</option>
                <option :value="service.id" v-for="service in services" :key="service.id">{{ service.name }}</option>
            </select>
            <label>Project description</label>
            <textarea v-model="form.description" rows="4" required></textarea>
            <label>Project budget</label>
            <input type="text" placeholder="Enter project budget" v-model="form.development_cost" required>
        </div>
        <div class="form-inputs" v-else-if="modalform.type != 'delete' && form && modalform.form == 'hostings'">
            <label>Project name</label>
            <input type="text" placeholder="Enter project name" v-model="form.name" required>
            <label>Domain name</label>
            <input type="text" placeholder="Enter domain name" v-model="form.domain" required>
            <label>Hostin plan</label>
            <select v-model="form.hosting_id">
                <option value="" selected>Select Hosting plan</option>
                <option :value="plan.id" v-for="plan in plans" :key="plan.id">{{ plan.name }} (${{ plan.price }})</option>
            </select>
            <label>Project description</label>
            <textarea v-model="form.description" placeholder="Write a description" rows="4" required></textarea>
        </div>
        <label class="warn-text" v-else>Are you  sure you want to delete this proposal ?</label>
        <button>{{ modalform.type }} Proposal</button>
    </form>
</div>
</template>

<script>
export default {
    name: "ProposalModal",
    props: {
        current: {
            type: Object,
            default: null
        },
        modalform: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            services: [],
            plans: []
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = this.current
        },
        submitform(){
            this.loading = true
            if(this.modalform.type == 'delete'){
                this.$store.dispatch('DELETE_PROJECT_REQUEST', this.form.id)
                    .then((res) => {
                        if(res.data.message == 'Project deleted successfully'){
                            if(res.data.status == 'failed'){
                                this.$notify({
                                    type: 'error',
                                    text: res.data.message
                                })
                                return
                            }
                            this.$notify({
                                type: 'success',
                                text: res.data.message
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', this.modalform.form)
                        }else{
                            this.$notify({
                                type: 'error',
                                text: res.data.message
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } else if(this.modalform.type == 'add'){
                this.form.project_type = "personal";
                this.$store.dispatch('CREATE_PROJECT_REQUEST', this.form)
                .then((res) => {
                    if(res.data.status == 'failed'){
                        this.$notify({
                            type: 'error',
                            text: res.data.message
                        })
                        return
                    }
                    this.$notify({
                        type: 'success',
                        text: res.data.message
                    })
                    this.loading = false
                    let formtype = this.modalform.form == 'hostings' ? 'hostings' : 'projects'
                    this.$emit('refresh', formtype)
                    this.$emit("close")
                    
                })
                .catch((err) => {
                    console.log(err);
                })
            } else if(this.modalform.type == 'edit'){
                this.$store.dispatch('UPDATE_PROJECT_REQUEST', this.form)
                    .then((res) => {
                        if(res.data.status == 'failed'){
                            this.$notify({
                                type: 'error',
                                text: res.data.message
                            })
                            return
                        }
                        this.$notify({
                            type: 'success',
                            text: res.data.message
                        })
                        this.loading = false
                        this.$emit("close")
                        this.$emit('refresh', this.modalform.form)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    },
    mounted() {
        this.handelform();
        if(this.modalform.form == 'hostings'){
            this.$getAllHostingPlans()
        }else{
            this.$getAllServices()
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
<template>
    <div class="about-story">
        <div class="story">
            <h2 class="heading">A Journey of <span>Innovation a</span>nd Dedication <span>.</span></h2>
            <p class="sub-heading">Where Passion and Purpose Unite.</p>
            <p class="story-descript">{{ $store.state.company.about_description }}</p>
            <!-- <p class="other">
                And our mission statement:
                Our mission is to deliver reliable digital solutions that enhance enduring client partnerships. We do this through client-centric excellence, innovation, and a commitment to transparency, fostering trust and empowering our clients' success.
            </p> -->
        </div>
        <div class="cover">
            <video src="@/assets/videos/IremeTechnologies.mp4" controls autoplay muted></video>
        </div>
    </div>
</template>

<script>
export default {
    name: "Our Story"
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.heading{
    font-size: 1.5rem;
    margin: 0 0 5px 0;
    span{
        color: $orange;
    }
}
.sub-heading{
    font-size: 0.9rem;
    color: $realblack;
    margin: 0 0 30px 0;
}
.about-story{
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50% 48%;
    margin: 50px 0 70px;
    padding: 20px 0;
    @media (max-width: 991px) {
        grid-template-columns: 100%;
        column-gap: 0;
        row-gap: 30px;
    }
    .story{
        .story-descript{
            font-size: 0.9rem;
            color: $realblack;
            margin: 0 0 20px 0;
            line-height: 1.7rem;
        }
        .other{
            font-size: 0.9rem;
            color: $realblack;
            margin: 0 0 10px 0;
            line-height: 1.7rem;
        }
    }
    .cover{
        width: 100%;
        border-radius: 7px;
        height: 400px;
        position: relative;
        overflow: hidden;
        @media (max-width: 500px) {
            height: 300px;
        }
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: #00000022;
        }
        .logo{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-10%);
            width: 200px;
            height: 200px;
            z-index: 1;
            @media (max-width: 500px) {
                width: 100px;
                height: 100px;
            }
            svg{
                width: 100%;
                height: 100%;
            }
        }
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
<script>
export default {
    methods:{
        $file(filename){
            return this.$store.state.apiUploadUrl + filename
        },
        $loggedAdmin(){
            return JSON.parse(localStorage.getItem('loggedAdmin'));
        },
        $loggedUser(){
            return JSON.parse(localStorage.getItem('loggedUser'));
        },
        $allArticles(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_BLOGS')
                .then(res =>{
                    this.loading = false;
                    this.articles = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllServices(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_SERVICES')
                .then(res =>{
                    this.loading = false;
                    this.services = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllHappyClients(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_HAPPYCLIENTS')
                .then(res =>{
                    this.loading = false;
                    this.happyclients = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllHostingPlans(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_HOSTING_PLANS')
                .then(res =>{
                    this.loading = false;
                    res.data.forEach(plan =>{
                        plan.attributes = JSON.parse(plan.attributes);
                    })
                    this.plans = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllMembers(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_TEAM_MEMBERS')
                .then(res =>{
                    this.loading = false;
                    this.team = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllActiveTestimonials(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_TESTIMONIALS_ACTIVE')
                .then(res =>{
                    this.loading = false;
                    this.activeTestimonials = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $getAllPortfolios(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_PORTFOLIO')
                .then(res =>{
                    this.loading = false;
                    res.data.forEach(portfolio =>{
                        portfolio.images = JSON.parse(portfolio.images);
                    })
                    this.portfolios = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        $changePassword(){
            if(this.password_form.new_password != this.password_form.confirm_password){
                this.$notify({
                    text: "New and Confirm Password does not match",
                    type: "error"
                })
                return;
            } else {
                this.loading = true;
                this.$store.dispatch('CHANGE_PASSWORD', this.password_form)
                    .then(res =>{
                        this.loading = false;
                        this.$notify({
                            text: res.data.message,
                            type: "success"
                        })
                        this.password_form = {
                            old_password: '',
                            new_password: '',
                            confirm_password: ''
                        }
                    })
                    .catch(err =>{
                        this.loading = false;
                        console.log(err);
                    })
            }
        }
    }
}
</script>
<template>
    <div class="navbar">
        <div class="humber" :class="{ 'active': humber }" @click="ToggleEvent()">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <router-link to="/" class="flex">
            <div class="logo">
                <!-- <img src="@/assets/logo.png" alt="" draggable="false"> -->
                <img :src="$file($store.state.company.logo)" alt="" draggable="false">
            </div>
        </router-link>
        <div class="links" :class="{ 'humber': humber }">
            <router-link to="/">Home</router-link>
                <span class="dot">•</span>
            <router-link to="/services">Services</router-link>
                <span class="dot">•</span>
            <router-link to="/hosting">Hosting</router-link>
                <span class="dot">•</span>
            <router-link to="/blog">Blog</router-link>
                <span class="dot">•</span>
            <router-link to="/about">About</router-link>
                <span class="dot">•</span>
            <router-link to="/contact">Get in touch</router-link>
        </div>
        <div class="auth">
            <div class="signin flex" v-if="!user">
                <router-link to="/signin" class="flex">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><path d="M24 4C18.494917 4 14 8.494921 14 14C14 19.505079 18.494917 24 24 24C29.505083 24 34 19.505079 34 14C34 8.494921 29.505083 4 24 4 z M 24 7C27.883764 7 31 10.116238 31 14C31 17.883762 27.883764 21 24 21C20.116236 21 17 17.883762 17 14C17 10.116238 20.116236 7 24 7 z M 12.5 28C10.032499 28 8 30.032499 8 32.5L8 33.699219C8 36.640082 9.8647133 39.277974 12.708984 41.091797C15.553256 42.90562 19.444841 44 24 44C28.555159 44 32.446744 42.90562 35.291016 41.091797C38.135287 39.277974 40 36.640082 40 33.699219L40 32.5C40 30.032499 37.967501 28 35.5 28L12.5 28 z M 12.5 31L35.5 31C36.346499 31 37 31.653501 37 32.5L37 33.699219C37 35.364355 35.927463 37.127823 33.677734 38.5625C31.428006 39.997177 28.068841 41 24 41C19.931159 41 16.571994 39.997177 14.322266 38.5625C12.072537 37.127823 11 35.364355 11 33.699219L11 32.5C11 31.653501 11.653501 31 12.5 31 z"/></svg>
                    </div>
                    <label>Sign in</label>
                </router-link>
            </div>
            <div class="gotoprofile flex" v-else>
                <router-link to="/profile" class="flex">
                    <div class="icon">
                        <img :src="$file(user.image)" alt="">
                    </div>
                    <label>{{ user.lastname }}</label>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            humber: false,
            user: {}
        }
    },
    methods:{
        ToggleEvent(){
            this.humber = !this.humber
        }
    },
    mounted(){
        window.scrollTo(0, 0)
        this.user = this.$loggedUser()
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.navbar{
    padding: 20px 50px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 100;
    background: #4444;
    background: #ffffff64;
    background: #ffffffda;
    left: 0;
    top: 0;
    @media (max-width: 768px) {
        padding: 20px 20px;
    }
    .humber{
        display: none;
        cursor: pointer;
        transition: all .4s;
        @media (max-width: 991px) {
            display: block;
        }
        span{
            display: block;
            width: 30px;
            height: 2px;
            border: 1px;
            background: $realblack;
            transition: all .4s;
            @media (max-width: 400px) {
                width: 25px;
            }
        }
        span:not(:last-child){
            margin-bottom: 7px;
        }
        &.active{
        transition-delay: 0.8s;
            // transition: .6s;
            transform: rotate(45deg);
        }
        &.active > span:nth-child(2){
            transition: .4s;
            width: 0;
        }
        &.active > span:nth-child(1),&.active > span:nth-child(3){
            transition-delay: .4s;
        }
        &.active > span:nth-child(1){
            transform: translateY(9px);
        }
        &.active > span:nth-child(3){
            transform: translateY(-9px) rotate(90deg);
        }
    }
    a{
        font-size: 0.85rem;
        text-decoration: none;
        transition: .4s;
        color: $black;
        svg{
            transition: .4s;
            fill: $black;
        }
        &.router-link-exact-active{
            color: $orange;
            font-weight: 600;
            svg{
                fill: $orange;
            }
        }
        &:hover{
            color: $orange;
            &.flex{
                .icon{
                    svg{
                        fill: $orange;
                    }
                }
            }
        }
        &.flex{
            display: flex;
            align-items: center;
            .logo{
                width: 150px;
                height: 40px;
                object-fit: contain;
                position: relative;
                @media (max-width: 700px) {
                    width: 100px;
                    height: 30px;
                }
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .links{
        display: flex;
        align-items: center;
        transition: .6s;
        a{
            padding: 4px 10px;
            margin: 0 13px;
            &.icon{
                width: 25px;
                height: 25px;
                border-radius: 100%;
                overflow: hidden;
                position: relative;
                svg{
                position: absolute;
                width: 80%;
                height: 80%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                }
            }
        }
        @media (max-width: 991px) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: calc(100vh - 80px);
            background: #00000085;
            flex-direction: column;
            // justify-content: center;
            padding: 20px 0 0 0;
            align-items: center;
            opacity: 0;
            position: none;
            transition: .4s;
            backdrop-filter: blur(10px);
        }
        &.humber{
            @media (max-width: 991px) {
                opacity: 1;
                pointer-events: all;
                .dot{
                    display: none;
                }
                a{
                    margin: 20px 0;
                    font-size: 1rem;
                    color: $white;
                    &.router-link-exact-active{
                        color: $orange;
                        font-weight: 600;
                        svg{
                            fill: $orange;
                        }
                    }
                }
            }
        }
    }
    .signin{
        display: flex;
        column-gap: 7px;
        align-items: center;
        cursor: pointer;
        &:hover{
            color: $orange;
        }
        &.flex{
            display: flex;
            .icon{
                svg{
                    fill: $orange;
                }
            }
        }
        label{
            font-size: 0.85rem;
            transition: .4s;
            cursor: pointer;
            @media (max-width: 700px) {
                font-size: 0.75rem;
            }
            @media (max-width: 600px) {
                display: none;
            }
            &::selection{
                background: none;
            }
        }
        .icon{
            width: 35px;
            height: 35px;
            position: relative;
            @media (max-width: 700px) {
                width: 40px;
                height: 40px;
            }
            svg{
                position: absolute;
                transition: .4s;
                fill: $black;
                width: 70%;
                height: 70%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            } 
        }
    }
    .gotoprofile{
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
            color: $orange;
        }
        label{
            font-size: 0.85rem;
            transition: .4s;
            cursor: pointer;
            margin: 0 0 0 10px;
            @media (max-width: 700px) {
                font-size: 0.75rem;
            }
            @media (max-width: 600px) {
                display: none;
            }
            &::selection{
                background: none;
            }
        }
        .icon{
            width: 35px;
            height: 35px;
            position: relative;
            img{
                position: absolute;
                transition: .4s;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                object-fit: cover;
                transform: translate(-50%,-50%);
                border-radius: 100%;
            } 
        }
    }
}
</style>
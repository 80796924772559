import axios from 'axios'
import { GET_ALL_HAPPYCLIENTS, CREATE_HAPPYCLIENT, UPDATE_HAPPYCLIENT, DELETE_HAPPYCLIENT } from '../actions/happyclients';
import { GET_ALL_HAPPYCLIENTS_URL, CREATE_HAPPYCLIENT_URL, UPDATE_HAPPYCLIENT_URL, DELETE_HAPPYCLIENT_URL } from '../variables';

const happyclients = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_HAPPYCLIENTS]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_HAPPYCLIENTS_URL
            })
        },
        [CREATE_HAPPYCLIENT]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CREATE_HAPPYCLIENT_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_HAPPYCLIENT]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + UPDATE_HAPPYCLIENT_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_HAPPYCLIENT]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "DELETE",
                url: rootState.apiBaseUrl + DELETE_HAPPYCLIENT_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
    },
}

export default happyclients;
<template>
<div class="overlay">
    <form class="form" @submit.prevent="submitform()">
        <div class="top">
            <h3 class="title">{{ type }} Project</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="type != 'delete' && form">
            <label>Title</label>
            <input type="text" v-model="form.title" placeholder="Enter project title" required>
            <label>Type</label>
            <select v-model="form.category" required>
                <option value="" hidden selected>Select Type</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobile Development">Mobile Development</option>
                <option value="Desktop Development">Desktop Development</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Graphics Design">Graphics Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Content Writing">Content Writing</option>
                <option value="Other">Other</option>
            </select>
            <label>Description</label>
            <textarea rows="4" v-model="form.description" placeholder="Enter project description"></textarea>
            <label>Link</label>
            <input type="text" v-model="form.link" placeholder="Enter project link">
            <label>Visibility</label>
            <select v-model="form.visibility" required>
                <option value="" disabled selected>Select visibility</option>
                <option value="public">Public</option>
                <option value="unlisted">Unlisted</option>
            </select>
            <label>Image</label>
            <Uploader
                v-if="form.images"
                :multiple="true"
                :uploadUrl="'files/upload'"
                @imagesUploaded="(data) =>{ form.images = data }"
                :value="form.images"
            />
            <Uploader
                v-else
                :multiple="true"
                :uploadUrl="'files/upload'"
                @imagesUploaded="(data) =>{ form.images = data }"
                :value="form.images"
            />
        </div>
        <label class="warn-text" v-else>Are you sure you want to detete this project ?</label>
        <button>{{ type }} Project</button>
    </form>
</div>
</template>

<script>
export default {
    name: "MemberModal",
    props: {
        current: {
            type: Object,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = this.current
        },
        submitform(){
            this.form.images = JSON.stringify(this.form.images)
            if(this.type == "add"){
                this.$store.dispatch("CREATE_PORTFOLIO", this.form)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    console.log(err)
                })
            }else if(this.type == "edit"){
                // this.form.images = JSON.stringify(this.form.images)
                this.$store.dispatch("UPDATE_PORTFOLIO", this.form)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    console.log(err)
                })
            }else if(this.type == "delete"){
                this.$store.dispatch("DELETE_PORTFOLIO", this.form.id)
                .then(res => {
                    this.$notify({
                        text: res.data.message,
                        type: "success"
                    })
                    this.$emit("close")
                    this.$emit("refresh")
                })
                .catch(err => {
                    console.log(err)
                })
            }
        }
    },
    mounted() {
        this.handelform()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
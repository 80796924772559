// Auth
export const ADMIN_LOGIN_URL = "login/admin";
export const USER_LOGIN_URL = "login/user";
export const USER_REGISTER_URL = "register/user";

export const UPDATE_PERSONAL_PROFILE_URL = "profile/update";
export const LOGOUT_URL = "logout";

// Dashboard
export const GET_DASHBOARD_DATA_URL = "admin/dashboard";

// Admins
export const GET_ALL_ROLES_URL = "roles";
export const GET_ALL_ADMINS_URL = "admins";
export const ADMIN_REGISTER_URL = "register/admin";
export const DELETE_ADMIN_URL = "admins/";
export const UPDATE_ADMIN_URL = "admins/";
// Services
export const GET_ALL_SERVICES_URL = "services";
export const CREATE_SERVICE_URL = "services";
export const DELETE_SERVICE_URL = "services/";
export const UPDATE_SERVICE_URL = "services/";
// Hostings
export const GET_ALL_HOSTING_PLANS_URL = "hosting";
export const GET_ALL_ACTIVE_HOSTINGS_URL = "hosting/active-hosts";
export const GET_ALL_HOSTING_REQUESTS_URL = "hosting/requests";
export const GET_ALL_MY_HOSTING_URL = "hosting/myhosting";
export const CREATE_HOSTING_URL = "hosting";
export const DELETE_HOSTING_URL = "hosting/";
export const UPDATE_HOSTING_URL = "hosting/";
export const APPROVE_HOSTING_URL = "hosting/request/approve/";
export const DECLINE_HOSTING_URL = "hosting/request/decline/";
// Blogs
export const GET_ALL_BLOGS_URL = "blogs";
export const GET_SINGLE_BLOG_URL = "blogs/";
export const CREATE_BLOG_URL = "blogs";
export const UPDATE_BLOG_URL = "blogs/";
export const DELETE_BLOG_URL = "blogs/";
export const ADD_BLOG_COMMENT_URL = "blog/comment";
// Team
export const GET_ALL_TEAM_MEMBERS_URL = "team";
export const CREATE_TEAM_MEMBER_URL = "team";
export const DELETE_TEAM_MEMBER_URL = "team/";
export const UPDATE_TEAM_MEMBER_URL = "team/";
// Portfolio
export const GET_ALL_PORTFOLIO_URL = "portfolios";
export const CREATE_PORTFOLIO_URL = "portfolios";
export const DELETE_PORTFOLIO_URL = "portfolios/";
export const UPDATE_PORTFOLIO_URL = "portfolios/";
// Projects
export const GET_ALL_PROJECTS_URL = "projects";
export const GET_ONE_PROJECT_URL = "projects/";
export const GET_ALL_PROJECTS_REQUESTS_URL = "projects/requests";
export const CREATE_PROJECT_REQUEST_URL = "projects";
export const DELETE_PROJECT_REQUEST_URL = "projects/";
export const UPDATE_PROJECT_REQUEST_URL = "projects/";
export const APPROVE_PROJECT_REQUEST_URL = "projects/request/approve/";
export const DECLINE_PROJECT_REQUEST_URL = "projects/request/decline/";
// Project Feedbacks and Updates
export const CREATE_FEEDBACK_UPDATE_URL = "project/feedbacks-updates";
export const DELETE_FEEDBACK_UPDATE_URL = "project/feedbacks-updates/";
export const UPDATE_FEEDBACK_UPDATE_URL = "project/feedbacks-updates/";
// HappyClients
export const GET_ALL_HAPPYCLIENTS_URL = "happy-clients";
export const CREATE_HAPPYCLIENT_URL = "happy-clients";
export const DELETE_HAPPYCLIENT_URL = "happy-clients/";
export const UPDATE_HAPPYCLIENT_URL = "happy-clients/";
// Testimonials
export const GET_ALL_TESTIMONIALS_ACTIVE_URL = "testimonials/active";
export const GET_ALL_TESTIMONIALS_REQUESTS_URL = "testimonials/requests";
export const CREATE_TESTIMONIAL_URL = "testimonials";
export const DELETE_TESTIMONIAL_URL = "testimonials/";
export const UPDATE_TESTIMONIAL_URL = "testimonials/";
export const APPROVE_TESTIMONIAL_URL = "testimonials/request/approve/";
export const DECLINE_TESTIMONIAL_URL = "testimonials/request/decline/";
// Contacts
export const GET_ALL_CONTACTS_URL = "contacts";
export const CREATE_CONTACT_URL = "contacts";
// Settings
export const GET_ALL_COMPANY_PROFILE_URL = "company-profile";
export const UPDATE_COMPANY_PROFILE_URL = "company-profile";
export const CHANGE_PASSWORD_URL = "settings/change-password";
// Subscribers
export const GET_ALL_SUBSCRIBERS_URL = "subscribers";
export const CREATE_SUBSCRIPTION_URL = "subscribers";
export const DELETE_SUBSCRIBER_URL = "subscribers/";
// General
export const GET_ALL_COMPANY_DATA_URL = "site-company-profile";


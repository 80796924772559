<template>
  <div class="dashboardContent">
    <Sidebar />
    <div class="content">
        <Topbar />
        <Loader v-if="loading" />
        <Modal v-if="modal" :current="form" :modalform="modalform" @close="closeModal()" @refresh="getTestimonials" />
        <div class="detailed-content">
            <div class="locator-search">
                <h1 class="locator">Testimonials</h1>
                <div class="end">
                    <div class="search">
                        <input type="text" placeholder="Search">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_15_152)"> <rect width="24" height="24" fill="white"></rect> <circle cx="10.5" cy="10.5" r="6.5" stroke="#ccc" stroke-linejoin="round"></circle> <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#000000"></path> </g> <defs> <clipPath id="clip0_15_152"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                    </div>
                    <button class="btn-add" @click="openModal('add', null, 'active')">Add new review</button>
                </div>
            </div>
            <div class="tabs">
                <label class="tab" @click="tab = 'active'" :class="{ active: tab == 'active' }">Active</label>
                <label class="tab" @click="tab = 'pending'" :class="{ active: tab == 'pending' }">Pending / Requested</label>
            </div>
            <div class="active" v-if="tab == 'active'">
                <div class="table" v-if="activeTestimonials.length > 0">
                    <div class="headings">
                        <h3 class="heading">Image</h3>
                        <h3 class="heading">Name</h3>
                        <h3 class="heading">Position</h3>
                        <h3 class="heading">Message</h3>
                        <h3 class="heading">Visibility</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="service" v-for="testimonial in activeTestimonials" :key="testimonial.id">
                        <label class="data img"><img :src="$file(testimonial.image)" alt=""></label>
                        <label class="data">{{ testimonial.names }}</label>
                        <label class="data">{{ testimonial.title }}</label>
                        <label class="data">{{ testimonial.description }}</label>
                        <label class="data visibility" :class="{ 'public' : testimonial.visibility == 'public' }">
                            {{ testimonial.visibility }}
                        </label>
                        <div class="data actions">
                            <button class="btn" v-if="!testimonial.user_id" @click="openModal('edit', testimonial, 'active')">Edit</button>
                            <button class="btn" @click="openModal('view', testimonial, 'active')">View</button>
                            <button class="btn del" @click="openModal('delete', testimonial, 'active')">Delete</button>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No records available</label>
            </div>
            <div class="active" v-else>
                <div class="table" v-if="requestedTestimonials.length > 0">
                    <div class="headings">
                        <h3 class="heading">Image</h3>
                        <h3 class="heading">Name</h3>
                        <h3 class="heading">Position</h3>
                        <h3 class="heading">Message</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="service" v-for="testimonial in requestedTestimonials" :key="testimonial.id">
                        <label class="data img"><img :src="$file(testimonial.image)" alt=""></label>
                        <label class="data">{{ testimonial.names }}</label>
                        <label class="data">{{ testimonial.title }}</label>
                        <label class="data">{{ testimonial.description }}</label>
                        <div class="data actions">
                            <button class="btn" @click="openModal('view', testimonial, 'request')">View</button>
                            <button class="btn" @click="openModal('approve', testimonial, 'request')">Approve</button>
                            <button class="btn del" @click="openModal('decline', testimonial, 'request')">Decline</button>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No requests received</label>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Modal from './components/TestimonialModal.vue';
export default {
    name: "AdminTestimonials",
    components: { Modal },
    data() {
        return {
            tab: 'active',
            modal: false,
            modalform: null,
            loading: false,
            activeTestimonials: [],      
            requestedTestimonials: [],
            form: {
                title: "",
                names: "",
                description: "",
                image: "",
                visibility: ""
            }
        }
    },
    methods:{
        openModal(type, content, modalform){
            this.modal = true;
            this.modalform = { type: type, form: modalform };
            type != 'add' ? { ...this.form } = content : ''
        },
        closeModal(){
            this.modal = false;
            this.modalform = null;
            this.form = {
                title: "",
                names: "",
                description: "",
                status: "",
                images: ""
            }
        },
        getAllRequestedReviews(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_TESTIMONIALS_REQUESTS')
                .then(res =>{
                    this.loading = false;
                    this.requestedTestimonials = res.data;
                })
                .catch(err =>{
                    this.loading = false;
                    console.log(err);
                })
        },
        getTestimonials(type){
            if(type == 'all'){
                this.$getAllActiveTestimonials()
                this.getAllRequestedReviews()
            } else if(type == 'active'){
                this.$getAllActiveTestimonials()
            } else if(type == 'request'){
                this.getAllRequestedReviews()
            }
        }
    },
    mounted() {
        this.$getAllActiveTestimonials()
        this.getAllRequestedReviews()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/main.scss";
.detailed-content{
    padding: 20px;
    .tabs{
        display: flex;
        align-items: center;
        margin: 20px 0px;
        column-gap: 30px;
        .tab{
            font-size: 0.8rem;
            font-weight: 500;
            color: #333;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            &:hover{
                background-color: #f1f1f1;
            }
            &.active{
                background-color: #f5a62361;
                color: #905b06;
            }
        }
    }
    .table{
        margin: 20px;
        .headings{
            display: grid;
            grid-template-columns: 0.7fr 0.7fr 0.6fr 1.5fr 0.5fr 1.5fr;
            column-gap: 10px;
            padding: 10px 0;
            margin: 0 0 15px;
            border-bottom: 1px solid #dbdbdb8b;
            &.requested{
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
            .heading{
                font-size: 0.8rem;
                font-weight: 500;
                color: #333;
            }
        }
        .service{
            display: grid;
            align-items: center;
            grid-template-columns: 0.7fr 0.7fr 0.6fr 1.5fr 0.5fr 1.5fr;
            column-gap: 10px;
            margin: 0 0 15px;
            padding: 10px 0;
            border-bottom: 1px solid #dbdbdb54;
            &.requested{
                grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr 0.7fr;
            }
            .data{
                font-size: 0.8rem;
                color: #333;
                height: fit-content;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                &.img{
                    img{
                        width: 70px;
                        height: 70px;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }
                &.actions{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 10px;
                    &.requested{
                        flex-direction: column;
                        row-gap: 10px;
                    }
                    button{
                        padding: 5px 20px;
                        cursor: pointer;
                        border: none;
                        border-radius: 5px;
                        background-color: #f1f1f1;
                        font-size: 0.8rem;
                        font-weight: 500;
                        color: #333;
                        cursor: pointer;
                        transition: .6s;
                        &:hover{
                            background-color: #ccc;
                        }
                        &.del{
                            background-color: salmon;
                            color: #fff;
                        }
                    }
                }
                &.visibility{
                    color: #58092f;
                    background-color: #de8b7c;
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: fit-content;
                    text-transform: capitalize;
                }
                &.public{
                    color: #09580c;
                    background-color: #7cde7f;
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: fit-content;
                }
            }
        }
    }
}
</style>
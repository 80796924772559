import { createRouter, createWebHistory } from 'vue-router'
// Site
import Site from '@/Site.vue'
// Pages
import Home from '@/views/Site/Home.vue'
import Contact from '@/views/Site/Contact.vue'
import About from '@/views/Site/About.vue'
import Blog from '@/views/Site/Blog.vue'
import Article from '@/views/Site/Article.vue'
import Services from '@/views/Site/Services.vue'
import Testimonials from '@/views/Site/Testimonials.vue'
import Hostings from '@/views/Site/Hosting.vue'
// Admin
import Dashboard from '@/Dashboard.vue'
// Pages
import Admin from '@/views/Admin/Home.vue'
import AdminDashboard from '@/views/Admin/Dashboard.vue'
import AdminAdmins from '@/views/Admin/Admins/Admins.vue'
import AdminServices from '@/views/Admin/Services/Services.vue'
import AdminTeam from '@/views/Admin/Team/Team.vue'
import AdminBlogs from '@/views/Admin/Blog/Blog.vue'
import AdminProjects from '@/views/Admin/Projects/Projects.vue'
import AdminProject from '@/views/Admin/Project/Project.vue'
import AdminPortfolio from '@/views/Admin/Portfolio/Portfolio.vue'
import AdminSubscribers from '@/views/Admin/Subscribers/Subscribers.vue'
import AdminContacts from '@/views/Admin/Contacts/Contacts.vue'
import AdminHappyClients from '@/views/Admin/HappyClients/HappyClients.vue'
import AdminTestimonials from '@/views/Admin/Testimonials/Testimonials.vue'
import AdminHosting from '@/views/Admin/Hosting/Hosting.vue'
import AdminDomain from '@/views/Admin/Domain/Domain.vue'
import AdminSettings from '@/views/Admin/Settings.vue'

// User
import Signin from '@/views/User/Signin.vue'
import Signup from '@/views/User/Signup.vue'
// Pages
import UserProfile from '@/views/User/Profile/Profile.vue'

const routes = [
  {
    path: '/',
    name: 'Site',
    component: Site,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'services',
        name: 'services',
        component: Services
      },
      {
        path: 'hosting',
        name: 'hosting',
        component: Hostings
      },
      {
        path: 'signin',
        name: 'Signin',
        component: Signin
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      {
        path: 'blog',
        name: 'Blog',
        component: Blog
      },
      {
        path: 'blog/:slug',
        name: 'Article',
        component: Article
      },
      {
        path: 'testimonials',
        name: 'Reviews',
        component: Testimonials
      },
      {
        path: 'profile',
        name: 'Profile',
        component: UserProfile
      }
    ]
  },
  {
    path: '/admin',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'Admin',
        component: Admin
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: AdminDashboard
      },
      {
        path: 'admins',
        name: 'Admins',
        component: AdminAdmins
      },
      {
        path: 'services',
        name: 'Services',
        component: AdminServices
      },
      {
        path: 'team',
        name: 'Team',
        component: AdminTeam
      },
      {
        path: 'blogs',
        name: 'Blogs',
        component: AdminBlogs
      },
      {
        path: 'projects',
        name: 'projects',
        component: AdminProjects
      },
      {
        path: 'projects/:id/:name',
        name: 'project',
        component: AdminProject
      },
      {
        path: 'portfolio',
        name: 'portfolio',
        component: AdminPortfolio
      },
      {
        path: 'subscribers',
        name: 'Subscribers',
        component: AdminSubscribers
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: AdminContacts
      },
      {
        path: 'happyclients',
        name: 'HappyClients',
        component: AdminHappyClients
      },
      {
        path: 'testimonials',
        name: 'Testimonials',
        component: AdminTestimonials
      },
      {
        path: 'hosting',
        name: 'Hosting',
        component: AdminHosting
      },
      {
        path: 'hosting/:id/:name',
        name: 'Domain',
        component: AdminDomain
      },
      {
        path: 'settings',
        name: 'Settings',
        component: AdminSettings
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
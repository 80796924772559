import axios from 'axios'
import { ADMIN_LOGIN, LOGOUT, UPDATE_PERSONAL_PROFILE, USER_LOGIN, USER_REGISTER } from '../actions/auth';
import { ADMIN_LOGIN_URL, LOGOUT_URL, UPDATE_PERSONAL_PROFILE_URL, USER_LOGIN_URL, USER_REGISTER_URL } from '../variables';

const auth = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [ADMIN_LOGIN]: ({ state, getters, commit, rootState }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + ADMIN_LOGIN_URL,
                data: payload
            })
        },
        [USER_LOGIN]: ({ state, getters, commit, rootState }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + USER_LOGIN_URL,
                data: payload
            })
        },
        [USER_REGISTER]: ({ state, getters, commit, rootState }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + USER_REGISTER_URL,
                data: payload
            })
        },
        [LOGOUT]: ({ state, getters, commit, rootState, rootGetters }) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + LOGOUT_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_PERSONAL_PROFILE]: ({ state, getters, commit, rootState, rootGetters }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + UPDATE_PERSONAL_PROFILE_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    }
}

export default auth;
import { createStore } from 'vuex'
import files from './modules/files';
import auth from './modules/auth';
import dashboard from './modules/dashboard';
import admins from './modules/admins';
import services from './modules/services';
import hosting from './modules/hosting';
import blogs from './modules/blogs';
import team from './modules/team'
import projects from './modules/projects';
import testimonials from './modules/testimonials';
import happyclients from './modules/happyclients';
import subscribers from './modules/subscribers';
import contacts from './modules/contacts';
import settings from './modules/settings';

export default createStore({
  state: {
    apiBaseUrl: '',
    apiUploadUrl: '',
    company: {}
  },
  getters: {
    get_access_token(state){
      if(localStorage.getItem('AdminToken')){
        let user = localStorage.getItem('AdminToken');
        return user;
      }else if(localStorage.getItem('UserToken')){
        let user = localStorage.getItem('UserToken');
        return user;
      }
      return 'No token';
    }
  },
  mutations: {
    setApiBaseUrl(state, payload) {
      state.apiBaseUrl = payload
    },
    setApiUploadUrl(state, payload) {
      state.apiUploadUrl = payload
    }
  },
  actions: {
    initializeApiBaseUrl({ commit }) {
      if (process.env.NODE_ENV === 'development') {
        commit('setApiBaseUrl', 'http://127.0.0.1:8000/api/');
        commit('setApiUploadUrl', 'http://127.0.0.1:8000/files/images/');
      } else if (process.env.NODE_ENV === 'production') {
        commit('setApiBaseUrl', 'https://ireme.infostore.store/api/');
        commit('setApiUploadUrl', 'https://ireme.infostore.store/files/images/');
      }
    }
  },
  modules: {
    files,
    auth,
    dashboard,
    admins,
    services,
    hosting,
    blogs,
    team,
    projects,
    happyclients,
    testimonials,
    subscribers,
    contacts,
    settings
  }
})

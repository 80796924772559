<template>
<div class="mainContainer">
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-about.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1><span>About</span> us.</h1>
                <p>Connecting You to Solutions.</p>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <OurStory />
        <div class="products-we-offer">
                <h2 class="heading">Software <span>products</span> in <span>place</span>.</h2>
                <p class="sub-heading">Where Passion and Purpose Unite.</p>
            <div class="offers">
                <label class="offer"><span>></span> Content Management Systems</label>
                <label class="offer"><span>></span> Property Management System</label>
                <label class="offer"><span>></span> Customer Management</label>
                <label class="offer"><span>></span> Property Management System</label>
                <label class="offer"><span>></span> eCommerce Website</label>
                <label class="offer"><span>></span> Enterprise Resource Planning</label>
                <label class="offer"><span>></span> Inventory Management Software</label>
                <label class="offer"><span>></span> And more.</label>
            </div>
    </div>
        </div>
    <div class="contentContainer">
        <Projects />
        <Team />
    </div>
        <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
import OurStory from '@/components/Site/OurStory.vue'
export default {
    name: "About",
    components: {
        OurStory
    },
    data(){
        return{
            loading: false,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.heading{
    font-size: 1.5rem;
    margin: 0 0 5px 0;
    span{
        color: $orange;
    }
}
.sub-heading{
    font-size: 0.9rem;
    color: $realblack;
    margin: 0 0 30px 0;
}
.products-we-offer{
    margin: 30px 0;
    .heading,.sub-heading{
        text-align: center;
        @media (max-width: 767px) {
            text-align: left;
        }
    }
    .offers{
        padding: 0 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 30px;
        @media (max-width: 991px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }
        .offer{
            font-size: 0.9rem;
            span{
                color: $orange;
            }
        }
    }
}
</style>
import axios from "axios";
import { GET_DASHBOARD_DATA } from "../actions/dashboard";
import { GET_DASHBOARD_DATA_URL } from "../variables";

const dashboard = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_DASHBOARD_DATA]: ({ state, getters, commit, rootState, rootGetters }, payload) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_DASHBOARD_DATA_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    }
}

export default dashboard;
import axios from 'axios'

const files = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        ['UPLOAD_FILE']: ({ rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + 'upload',
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        },
        ['DELETE_FILE']: ({ rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + 'file/delete/' + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        }
    }
}
export default files;
<template>
  <div class="happy-clients" v-if="happyclients.length > 0">
    <h2 class="heading">Our <span>Happy</span> Clients.</h2>
    <p class="sub-heading">From Ideas to Applications.</p>
    <div class="our-happy-clients">
        <div class="categories">
            <div
            class="category"
            :class="{ active: selectedCategory == category }"
            v-for="(category, index) in categories"
            :key="index"
            @click="selectedCategory = category"
            >
            {{ category }}
            </div>
        </div>
        <div class="clients">
            <a :href="client.link" target="_blank" class="client" v-for="client in clients" :key="client.id">
                <img :src="$file(client.image)" alt="client" draggable="false" />
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HappyClients',
    computed: {
        clients() {
            if (this.selectedCategory == 'All') {
                return this.happyclients.filter(client => client.type == 1)
            } else {
                return this.happyclients.filter(
                    (client) => client.category == this.selectedCategory
                )
            }
        },
    },
    data() {
        return {
            loading: false,
            categories: [ 'All', 'Education', 'E-commerce', 'Healthcare', 'Hospitality', 'NGO', 'Churches' ],
            selectedCategory: 'All',
            happyclients: []
        }
    },
    mounted(){
        this.$getAllHappyClients()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
    .happy-clients {
        padding: 2rem 0;
        .heading{
            font-size: 1.5rem;
            margin: 0 0 5px 0;
            span{
                color: $orange;
            }
        }
        .sub-heading{
            font-size: 0.9rem;
            color: $realblack;
            margin: 0 0 30px 0;
        }
        .our-happy-clients {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2rem;
            .categories {
                display: flex;
                column-gap: 20px;
                row-gap: 10px;
                margin: 10px 0 40px;
                flex-wrap: wrap;
                justify-content: center;
                .category {
                    border: 1px solid $orange;
                    font-size: 0.8rem;
                    padding: 5px 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    color: $orange;
                    &.active {
                        background: $orange;
                        color: white;
                    }
                }
            }
            .clients {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                row-gap: 40px;
                column-gap: 40px;
                @media (max-width: 730px){
                    grid-template-columns: repeat(3, 1fr);
                }
                @media (max-width: 450px){
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 380px){
                    grid-template-columns: 1fr;
                }
                .client {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    height: 150px;
                    img {
                        filter: grayscale(100%);
                        transition: .6s;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    &:hover {
                        img {
                            filter: grayscale(0%);
                        }
                    }
                    .details {
                        margin-top: 1rem;
                        h3 {
                            font-size: 1.2rem;
                        }
                        p {
                            color: grey;
                        }
                    }
                }
            }
        }
    }
</style>

import store from '@/store';
<template>
  <div class="view">
        <div class="locator">
            <h1 class="locator-heading">My hostings</h1>
            <button @click="openModal('add', null, 'hostings')">
                <svg fill="#ffffff" viewBox="0 0 16.00 16.00" id="request-sent-16px" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.00016"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="Path_50" data-name="Path 50" d="M-11.5,0h-11A2.5,2.5,0,0,0-25,2.5v8A2.5,2.5,0,0,0-22.5,13h.5v2.5a.5.5,0,0,0,.309.462A.489.489,0,0,0-21.5,16a.5.5,0,0,0,.354-.146L-18.293,13H-11.5A2.5,2.5,0,0,0-9,10.5v-8A2.5,2.5,0,0,0-11.5,0ZM-10,10.5A1.5,1.5,0,0,1-11.5,12h-7a.5.5,0,0,0-.354.146L-21,14.293V12.5a.5.5,0,0,0-.5-.5h-1A1.5,1.5,0,0,1-24,10.5v-8A1.5,1.5,0,0,1-22.5,1h11A1.5,1.5,0,0,1-10,2.5Zm-2.038-3.809a.518.518,0,0,1-.109.163l-2,2A.5.5,0,0,1-14.5,9a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.708L-13.707,7H-18.5A1.5,1.5,0,0,0-20,8.5a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5A2.5,2.5,0,0,1-18.5,6h4.793l-1.147-1.146a.5.5,0,0,1,0-.708.5.5,0,0,1,.708,0l2,2a.518.518,0,0,1,.109.163A.505.505,0,0,1-12.038,6.691Z" transform="translate(25)"></path> </g></svg>
                Request hosting
            </button>
        </div>
        <div class="listings" v-if="hostings.length > 0">
            <div class="headings">
                <h3>Name</h3>
                <h3>Domain</h3>
                <h3>Status</h3>
                <h3>Expiry</h3>
                <h3></h3>
            </div>
            <div class="list" v-for="hosting in hostings" :key="hosting.id">
                <label>{{ hosting.name }}</label>
                <label>{{ hosting.domain }}</label>
                <label class="status">{{ hosting.status }}</label>
                <label v-if="hosting.domain_exp">{{ hosting.domain_exp }}</label>
                <label v-else>Not set</label>
                <div>
                    <button class="view" @click="viewProject(hosting)"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="3.5" stroke="#FFFF"></circle> <path d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="#FFFF"></path> </g></svg></button>
                    <button class="edit" @click="openModal('edit', hosting, 'hostings')"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.1498 7.93997L8.27978 19.81C7.21978 20.88 4.04977 21.3699 3.32977 20.6599C2.60977 19.9499 3.11978 16.78 4.17978 15.71L16.0498 3.84C16.5979 3.31801 17.3283 3.03097 18.0851 3.04019C18.842 3.04942 19.5652 3.35418 20.1004 3.88938C20.6356 4.42457 20.9403 5.14781 20.9496 5.90463C20.9588 6.66146 20.6718 7.39189 20.1498 7.93997V7.93997Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                    <button class="delete" @click="openModal('delete', hosting, 'hostings')"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 6.98996C8.81444 4.87965 15.1856 4.87965 21 6.98996" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.00977 5.71997C8.00977 4.6591 8.43119 3.64175 9.18134 2.8916C9.93148 2.14146 10.9489 1.71997 12.0098 1.71997C13.0706 1.71997 14.0881 2.14146 14.8382 2.8916C15.5883 3.64175 16.0098 4.6591 16.0098 5.71997" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13V18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M19 9.98999L18.33 17.99C18.2225 19.071 17.7225 20.0751 16.9246 20.8123C16.1266 21.5494 15.0861 21.9684 14 21.99H10C8.91389 21.9684 7.87336 21.5494 7.07541 20.8123C6.27745 20.0751 5.77745 19.071 5.67001 17.99L5 9.98999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                </div>
            </div>
        </div>
        <label class="no-data" v-else>No current listing</label>
    </div>
</template>

<script>
export default {
    name: "MyHostings",
    props: {
        hostings: {
            type: Object,
            default: {}
        }
    },
    data(){
        return {
            loading: false,
        }
    },
    methods: {
        openModal(action, content, modalform){
            this.$emit('toggleModal', action, content, modalform);
        },
        viewProject(project){
            this.$emit('viewProject', project);
        }
    },
    mounted(){
        this.$emit('getprojects', 'hostings');
    }
}
</script>

<style lang="scss" scoped>
.locator{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px;
    .locator-heading{
        font-size: 1.1rem;
        font-weight: 500;
    }
    button{
        all: unset;
        color: #905b06;
        background-color: #f5a62361;
        font-size: 0.8rem;
        padding: 7px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: .6s;
        display: flex;
        align-items: center;
        column-gap: 15px;
        svg{
            fill: #905b06;
            width: 17px;
            height: 17px;
        }
    }
}
.view{
    width: 700px;
    margin: auto;
    .heading{
        font-size: 1rem;
        font-weight: 500;
        margin: 0 0 30px;
    }
    .no-data{
        font-size: 0.8rem;
        font-weight: 400;
        display: block;
        text-align: center;
        color: #333333f0;
        margin: 20px auto;
    }
    .listings{
        overflow-y: auto;
        .headings{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #f7f7f7;
            h3{
                font-size: 0.81rem;
                font-weight: 500;
                color: #333333c2;
                &:last-child{
                    text-align: right;
                }
            }
        }
        .list{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            justify-content: space-between;
            padding: 30px 0;
            column-gap: 20px;
            border-bottom: 1px solid #f7f7f7;
            label{
                font-size: 0.72rem;
                font-weight: 500;
                color: #333333f0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                &.status{
                    color: #f5a623;
                }
            }
            div{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 10px;
                button{
                    all: unset;
                    background-color: #f5a62361;
                    color: #905b06;
                    font-size: 0.8rem;
                    padding: 5px 15px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: .6s;
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    width: 15px;
                    height: 15px;
                    position: relative;
                    svg{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 70%;
                        height: 70%;
                    }
                    &.view{
                        background-color: #f5a623;
                    }
                    &.edit{
                        background-color: #23a1f5;
                    }
                    &.delete{
                        background-color: #f52358;
                    }
                }
            }
        }
    }
}
.wrapper-box{
    border: 1px solid #f7f7f7;
    padding: 15px 20px;
    margin: 0 0 20px;
    border-radius: 7px;
    width: 100%;
    .flex-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .profile{
            display: flex;
            align-items: center;
            column-gap: 20px;
            max-width: 400px;
            .img{
                width: 90px;
                height: 90px;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                flex-shrink: 0;
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #00000061;
                    opacity: 0;
                    transition: .6s;
                }
                svg{
                    opacity: 0;
                    transition: .6s;
                    pointer-events: none;
                    position: absolute;
                    cursor: pointer;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40%;
                    height: 40%;
                }
                &:hover{
                    &::before{
                        opacity: 1;
                    }
                    svg{
                        opacity: 1;
                        pointer-events: all;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    &.logo{
                        object-fit: contain;
                    }
                }
            }
            .info{
                .names{
                    font-size: 0.9rem;
                    font-weight: 500;
                    margin: 0 0 2px;
                }
                .role{
                    font-size: 0.75rem;
                    color: #7d7d7d;
                }
            }
        }
        .edit{
            all: unset;
            background-color: #f5a62361;
            color: #905b06;
            font-size: 0.8rem;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            display: flex;
            align-items: center;
            column-gap: 15px;
            svg{
                width: 15px;
                height: 15px;
            }
        }
        .info{
            width: 500px;
            .flex{
                display: flex;
                column-gap: 30px;
                &:not(:last-child){
                    margin: 0 0 20px;
                }
                div{
                    width: 50%;
                    overflow: hidden;
                    label{
                        font-size: 0.9rem;
                        font-weight: 500;
                        display: block;
                        margin: 0 0 4px;
                    }
                    p{
                        font-size: 0.8rem;
                        color: #7d7d7d;
                        &.description{
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &.preview{
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                            svg{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    input{
                        width: 100%;
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        padding: 7px 15px;
                        font-size: 0.8rem;
                        color: #333;
                        &:focus{
                            outline: none;
                            border-color: #f5a623;
                        }
                    }
                    a{
                        margin: 10px 0 0;
                        text-decoration: none;
                        font-size: 0.75rem;
                        color: #7d7d7d;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .security{
            width: 100%;
            .flex{
                display: flex;
                column-gap: 30px;
                width: 100%;
                &:not(:last-child){
                    margin: 0 0 20px;
                }
                div{
                    width: 100%;
                    label{
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: #333;
                        display: block;
                        margin: 0 0 5px;
                    }
                    input{
                        width: 100%;
                        border: 1px solid #dddddd;
                        border-radius: 4px;
                        padding: 7px 15px;
                        font-size: 0.8rem;
                        color: #333;
                        &:focus{
                            outline: none;
                            border-color: #f5a623;
                        }
                    }
                }
            }
            .btn-submit{
                all: unset;
                background-color: #f5a62361;
                color: #905b06;
                font-size: 0.8rem;
                padding: 7px 0;
                border-radius: 5px;
                cursor: pointer;
                transition: .6s;
                display: flex;
                align-items: center;
                width: 100%;
                column-gap: 15px;
                justify-content: center;
                svg{
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
</style>
<template>
<div class="overlay">
    <form class="form" @submit.prevent="sendReview()">
        <div class="top">
            <h3 class="title">Send a review</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs">
            <label>Title / Position</label>
            <input type="text" v-model="form.title" placeholder="Enter your title" required>
            <label>Content</label>
            <textarea rows="5" v-model="form.description" placeholder="Write essage" required></textarea>
            <label>Image</label>
            <div>
                <Uploader
                    v-if="form.image"
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="(data) =>{ form.image = data }"
                    :value="form.image"
                />
                <Uploader
                    v-else
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="(data) =>{ form.image = data }"
                    :value="form.image"
                />
            </div>
        </div>
        <button>Send a review</button>
    </form>
</div>
</template>

<script>
export default {
    name: "Review",
    data() {
        return {
            form: {
                title: '',
                description: '',
                image: ''
            }
        }
    },
    methods: {
        close() {
            this.form = { title: '', description: '', image: '' }
            this.$emit('close')
        },
        sendReview() {
            if(this.$loggedUser()){
                this.form.names = this.$loggedUser().firstname + ' ' + this.$loggedUser().lastname
                this.$store.dispatch('CREATE_TESTIMONIAL', this.form)
                    .then(res =>{
                        this.$notify({
                            text: res.data.message,
                            type: 'success'
                        })
                        this.$router.push('/')
                        this.close()
                    })
                    .catch(err =>{
                        console.log(err)
                    })
            } else {
                this.$notify({
                    text: 'You must be logged in to send a review',
                    type: 'error'
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
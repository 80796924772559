<template>
<SiteLoader v-if="loading" />
<div class="testimonials-section" v-if="availableTestimonials.length > 0">
    <div class="testimonials-section-description">
        <h2>Words of <span>Trust,</span> Stories of <span>Success</span>.</h2>
        <p>Discover what our valued clients have to say about their experiences with us. Trust, satisfaction, and success stories that inspire confidence.</p>
        <button v-if="$loggedUser()" @click="$router.push('#review')">Leave a review</button>
        <button class="more" @click="$router.push('testimonials')">See more</button>
    </div>
    <div class="testimonials">
        <swiper
            :effect="'cards'"
            :modules="modules"
            :loop="true"
            :autoplay="{
                delay: 3000,
                disableOnInteraction: false,
            }"
            :grabCursor="true"
            class="mySwiper"
        >
            <swiper-slide v-for="testimonial in availableTestimonials" :key="testimonial.id">
                <div class="profile">
                    <img :src="$file(testimonial.image)" alt="">
                </div>
                <p class="message">"{{ testimonial.description }}"</p>
                <div class="profile-descript">
                    <h3>{{ testimonial.names }}</h3>
                    <p>{{ testimonial.title }}</p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

// import required modules
import { EffectCards, Autoplay, Pagination } from 'swiper/modules';
export default {
    name: 'Testimonials',
    components: { Swiper, SwiperSlide },
    computed: { availableTestimonials(){ return this.activeTestimonials.filter(testimonial => testimonial.visibility == 'public') } },
    data() {
        return {
            modules: [EffectCards, Autoplay, Pagination],
            activeTestimonials: [],
            loading: false
        }   
    },
    methods: {
    },
    mounted(){
        this.$getAllActiveTestimonials()
    }
}
</script>
<style lang="scss" scoped>
    @import "../../scss/main.scss";
.testimonials-section {
    background: #fff;
    width: 100%;
    display: flex;
    column-gap: 50px;
    justify-content: space-around;
    padding: 50px 0;
    @media (max-width: 991px) {
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .testimonials-section-description{
        width: 40%;
        padding: 0 20px;
        @media (max-width: 991px) {
            width: 100%;
        }
        @media (max-width: 500px) {
            padding: 0 10px;
        }
        h2{
            font-size: 2rem;
            color: $realblack;
            margin-bottom: 10px;
            @media (max-width: 500px) {
                font-size: 1.5rem;
            }
            span{
                color: $orange;
            }
        }
        p{
            color: $realblack;
            font-size: 0.9rem;
            line-height: 1.7rem;
            @media (max-width: 500px) {
                font-size: 0.8rem;
            }
        }
        button{
            padding: 10px 20px;
            border: 1px solid $orange;
            border-radius: 5px;
            background-color: $orange;
            font-size: 0.8rem;
            font-weight: 400;
            color: $white;
            cursor: pointer;
            margin: 20px 20px 0 0;
            transition: .6s;
            &:hover{
                background-color: $white;
                color: $orange;
            }
            &.more{
                background-color: transparent;
                color: $orange;
                border: 1px solid $orange;
                &:hover{
                    background-color: $orange;
                    color: $white;
                }
            
            }
        }
    }
    .testimonials{
        @media (max-width: 991px) {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .swiper {
            width: 540px;
            height: 320px;
            @media (max-width: 660px) {
                width: 90%;
            }
            @media (max-width: 500px) {
                height: 340px;
            }
        }
        
        .swiper-slide {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 18px;
          padding: 10px 30px;
          text-align: center;
          .profile{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 20px;
            flex-shrink: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
          }
          .message{
            font-size: 0.9rem;
            color: $realblack;
            padding-bottom: 4px;
            margin: 0 0 10px;
            border-bottom: 1px solid rgb(201, 211, 221);
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .profile-descript{
            h3{
                font-size: 1rem;
                font-weight: 500;
                color: $darkorange;
            }
            p{
                font-size: 0.8rem;
                color: $realblack;
            }
          }
        }
        
        .swiper-slide {
          background-color: #ffffffac;
          backdrop-filter: blur(10px);
          box-shadow: 0px 10px 30px -2px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
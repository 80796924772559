import axios from 'axios';
import { CREATE_TEAM_MEMBER, DELETE_TEAM_MEMBER, GET_ALL_TEAM_MEMBERS, UPDATE_TEAM_MEMBER } from '../actions/team';
import { CREATE_TEAM_MEMBER_URL, DELETE_TEAM_MEMBER_URL, GET_ALL_TEAM_MEMBERS_URL, UPDATE_TEAM_MEMBER_URL } from '../variables';

const team = {
    state : {},
    getters : {},
    mutations : {},
    actions : {
        [GET_ALL_TEAM_MEMBERS]: ({ commit, state, rootState, rootGetters }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_TEAM_MEMBERS_URL,
            })
        },
        [CREATE_TEAM_MEMBER]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_TEAM_MEMBER_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_TEAM_MEMBER]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_TEAM_MEMBER_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_TEAM_MEMBER]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_TEAM_MEMBER_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    }
}

export default team;
import axios from 'axios';
import { APPROVE_HOSTING, CREATE_HOSTING, DECLINE_HOSTING, DELETE_HOSTING, GET_ALL_ACTIVE_HOSTINGS, GET_ALL_HOSTING_PLANS, GET_ALL_HOSTING_REQUESTS, GET_ALL_MY_HOSTING, UPDATE_HOSTING } from '../actions/hosting';
import { APPROVE_HOSTING_URL, CREATE_HOSTING_URL, DECLINE_HOSTING_URL, DELETE_HOSTING_URL, GET_ALL_ACTIVE_HOSTINGS_URL, GET_ALL_HOSTING_PLANS_URL, GET_ALL_HOSTING_REQUESTS_URL, GET_ALL_MY_HOSTING_URL, UPDATE_HOSTING_URL } from '../variables';

const hosting = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_HOSTING_PLANS]: ({ commit, getters, rootState, rootGetters }) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_HOSTING_PLANS_URL
            })
        },
        [GET_ALL_ACTIVE_HOSTINGS]: ({ commit, getters, rootState, rootGetters }) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_ACTIVE_HOSTINGS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [GET_ALL_HOSTING_REQUESTS]: ({ commit, getters, rootState, rootGetters }) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_HOSTING_REQUESTS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [GET_ALL_MY_HOSTING]: ({ commit, getters, rootState, rootGetters }) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_MY_HOSTING_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [CREATE_HOSTING]: ({ commit, getters, rootState, rootGetters }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CREATE_HOSTING_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [UPDATE_HOSTING]: ({ commit, getters, rootState, rootGetters }, payload) => {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + UPDATE_HOSTING_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [DELETE_HOSTING]: ({ commit, getters, rootState, rootGetters }, payload) => {
            return axios({
                method: "DELETE",
                url: rootState.apiBaseUrl + DELETE_HOSTING_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [APPROVE_HOSTING]: ({ commit, getters, rootState, rootGetters }, payload) => {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + APPROVE_HOSTING_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [DECLINE_HOSTING]: ({ commit, getters, rootState, rootGetters }, payload) => {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + DECLINE_HOSTING_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
    }
}

export default hosting;
import axios from 'axios'
import { GET_ALL_ADMINS, GET_ALL_ROLES, ADMIN_REGISTER, DELETE_ADMIN, UPDATE_ADMIN } from '../actions/admins'
import { ADMIN_REGISTER_URL, DELETE_ADMIN_URL, GET_ALL_ADMINS_URL, GET_ALL_ROLES_URL, UPDATE_ADMIN_URL } from '../variables'

const admins = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_ROLES]: ({ commit, getters, dispatch, rootGetters, rootState }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_ROLES_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [GET_ALL_ADMINS]: ({ commit, getters, dispatch, rootGetters, rootState }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_ADMINS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [ADMIN_REGISTER]: ({ commit, getters, dispatch, rootGetters, rootState }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + ADMIN_REGISTER_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_ADMIN]: ({ commit, getters, dispatch, rootGetters, rootState }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_ADMIN_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_ADMIN]: ({ commit, getters, dispatch, rootGetters, rootState }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_ADMIN_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    }
}

export default admins;
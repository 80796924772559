<template>
<div class="mainContainer">
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-blog.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1><span>Our</span> blog<span>.</span></h1>
                <p>Empowering Your Digital Future, One Line of Code at a Time.</p>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <Blog />
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
export default {
    name: "OurBlog"
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
</style>
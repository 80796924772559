<template>
<div class="mainContainer">
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-testimonial.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1>Our Client <span>Reviews</span>.</h1>
                <p>Words from our clients</p>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <div class="our-testimonials" v-if="activeTestimonials.length > 0">
            <h2 class="heading"><span>Rave </span>Reviews.</h2>
            <p class="sub-heading">Feedbacks.</p>
            <div class="testimonials">
                <div class="testimonial" v-for="testimonial in activeTestimonials" :key="testimonial.id">
                    <div class="icon">
                        <img :src="$file(testimonial.image)" :alt="testimonial.names">
                    </div>
                    <div class="details">
                        <h2 class="title">{{ testimonial.names }}</h2>
                        <p class="description">{{ testimonial.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <router-link to="/#review" v-if="activeTestimonials.length > 0" class="first">Leave a review</router-link>
        <router-link to="/#review" v-else  class="first">Be the first to leave a review</router-link>
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
export default {
    name: "Reviews",
    data() {
        return {
            activeTestimonials: [],
        };
    },
    mounted() {
        this.$getAllActiveTestimonials();
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.first{
    background: $orange;
    color: $white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.8rem;
    border: 1px solid $orange;
    width: fit-content;
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin: 20px auto;
    transition: all 0.3s ease;
    &:hover{
        background: $white;
        color: $orange;
    }
}
.our-testimonials {
    padding: 30px 0 0;
    margin: 30px 0 0;
    width: 100%;
    .heading{
        font-size: 1.5rem;
        margin: 0 0 5px 0;
        span{
            color: $orange;
        }
    }
    .sub-heading{
        font-size: 0.9rem;
        color: $realblack;
        margin: 0 0 30px 0;
    }
    .testimonials{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
        @media (max-width: 800px){
            grid-template-columns: repeat(1,1fr);
        }
        .testimonial{
            background: $white;
            margin: 0 0 20px 0;
            padding: 30px 20px;
            border-radius: 10px;
            // box-shadow: 0px 0px 10px 0px #0000001a;
            border: 1px solid #7171711b;
            text-align: center;
            display: flex;
            align-items: center;
            @media (max-width: 520px){
                flex-direction: column;
            }
            .icon{
                padding: 10px;
                background: $white;
                flex-shrink: 0;
                width: 90px;
                height: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                position: relative;
                border-radius: 100%;
                overflow: hidden;
                margin: 0 10px 0 0; 
                @media (max-width: 520px){
                    width: 70px;
                    height: 70px;
                }
                img,svg{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .details{
                max-height: 110px;
                overflow-y: auto;
                margin: 0 10px;
                .title{
                    font-size: 1.1rem;
                    font-weight: 500;
                    margin: 0 0 5px 0;
                }
                .description{
                    font-size: 0.8rem;
                    color: $realblack;
                }
            }
        }
    }
}
</style>
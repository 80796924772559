import axios from 'axios';
import { CREATE_CONTACT, GET_ALL_CONTACTS } from '../actions/contacts';
import { CREATE_CONTACT_URL, GET_ALL_CONTACTS_URL } from '../variables';

const contacts = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_CONTACTS]: ({ commit, rootState, rootGetters }) => {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_CONTACTS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [CREATE_CONTACT]: ({ commit, rootState, rootGetters }, payload) => {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CREATE_CONTACT_URL,
                data: payload
            })
        }
    }
}

export default contacts;
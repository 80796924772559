<template>
    <SiteLoader v-if="loading" />
    <div class="our-portfolio" v-if="availablePortfolios.length > 0">
        <h2 class="heading">Our <span>Portfolio</span>.</h2>
        <p class="sub-heading">From Ideas to Applications.</p>
        <div class="projects">
            <div class="project" v-for="project in availablePortfolios" :key="project.id">
                <div class="gallery">
                    <swiper
                        :direction="'vertical'"
                        :pagination="{
                        clickable: true,
                        }"
                        :modules="modules"
                        class="mySwiper"
                    >
                        <swiper-slide v-for="image in project.images" :key="image"><img :src="$file(image)" alt=""></swiper-slide>
                    </swiper>
                </div>
                <div class="project-info">
                    <label class="project-date">{{ project.date }}</label>
                    <h3 class="project-title">{{ project.title }}</h3>
                    <p class="project-description">{{ project.description }}</p>
                    <a :href="project.link" v-if="project.link" class="project-link" target="_blank">Visit Site</a>
                </div>
                <div class="project-type">{{ project.category }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
export default {
    name: 'Portfolio',
    components: { Swiper, SwiperSlide },
    computed: { availablePortfolios(){ return this.portfolios.filter(portfolio => portfolio.visibility == 'public') } },
    data(){
        return{
            modules: [Pagination],
            projects: [],
            loading: false,
            portfolios: []
        }
    },
    mounted(){
        this.$getAllPortfolios()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.our-portfolio {
    padding: 30px 0;
    margin: 30px 0;
    @media (max-width: 800px){
        padding: 25px 10px;
    }
    // background: #f5f5f5;
    .heading{
        font-size: 1.5rem;
        margin: 0 0 5px 0;
        span{
            color: $orange;
        }
    }
    .sub-heading{
        font-size: 0.9rem;
        color: $realblack;
        margin: 0 0 30px 0;
    }
    .projects{
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
        place-items: center;
        row-gap: 50px;
        @media (max-width: 1000px){
            grid-template-columns: 100%;
        }
        .project{
            width: 95%;
            display: grid;
            grid-template-columns: 20% 80%;
            align-items: center;
            column-gap: 30px;
            border-radius: 10px;
            padding: 30px 30px;
            position: relative;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            @media (max-width: 1340px){
                grid-template-columns: 30% 70%;
            }
            @media (max-width: 1140px){
                grid-template-columns: 100%;
                padding: 30px 30px 70px 30px;
            }
            .gallery{
                width: 200px;
                height: 200px;
                position: relative;
                right: 80px;
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                @media (max-width: 1140px){
                    right: 0;
                    top: -20%;
                    margin: auto;
                    text-align: center;
                }
                .mySwiper{
                    width: 100%;
                    height: 100%;
                    .swiper-slide{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .project-type{
                font-size: 0.8rem;
                color: $realblack;
                position: absolute;
                padding: 4px 10px;
                background: $orange;
                color: #fff;
                border-radius: 4px 0 0 4px;
                bottom: 20px;
                right: 0;
            }
            .project-info{
                height: fit-content;
                padding: 0 10px;
                @media (max-width: 1140px){
                    text-align: center;
                    margin: auto;
                }
                .project-date{
                    font-size: 0.8rem;
                    color: $realblack;
                    margin: 0 0 5px 0;
                }
                .project-title{
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: $realblack;
                    margin: 0 0 6px 0;
                }
                .project-description{
                    font-size: 0.8rem;
                    color: $realblack;
                    margin: 0 0 10px 0;
                    line-height: 1.5rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .project-link{
                    margin: auto;
                    font-size: 0.9rem;
                    color: $realblack;
                    text-decoration: none;
                    margin: 0 0 5px 0;
                    display: inline-block;
                    padding: 5px 10px;
                    border-radius: 4px;
                    transition: all 0.3s ease;
                    border: 1px solid $orange;
                    background: $white;
                    color: $orange;
                    cursor: pointer;
                    &:hover{
                        color: #fff;
                        background: $orange;
                    }
                }
            }
        }
    }
}
</style>
import axios from 'axios';
import { CHANGE_PASSWORD, GET_ALL_COMPANY_DATA, GET_ALL_COMPANY_PROFILE_SETTINGS, UPDATE_COMPANY_PROFILE_SETTINGS } from '../actions/settings';
import { CHANGE_PASSWORD_URL, GET_ALL_COMPANY_DATA_URL, GET_ALL_COMPANY_PROFILE_URL, UPDATE_COMPANY_PROFILE_URL } from '../variables';

const settings = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_COMPANY_PROFILE_SETTINGS]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_COMPANY_PROFILE_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_COMPANY_PROFILE_SETTINGS]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "PUT",
                url: rootState.apiBaseUrl + UPDATE_COMPANY_PROFILE_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [CHANGE_PASSWORD]: ({ commit, dispatch, rootState, rootGetters }, payload) =>  {
            return axios({
                method: "POST",
                url: rootState.apiBaseUrl + CHANGE_PASSWORD_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [GET_ALL_COMPANY_DATA]: ({ commit, dispatch, rootState, rootGetters }) =>  {
            return axios({
                method: "GET",
                url: rootState.apiBaseUrl + GET_ALL_COMPANY_DATA_URL,
            })
        }
    }
}

export default settings;
<template>
<div class="mainContainer">
    <Navbar />
    <div class="hero-section">
        <div class="overlayer">
            <img src="@/assets/images/bg-services.jpg" alt="hero">
        </div>
        <div class="hero-content">
            <div class="content">
                <h1><span>Our</span> services.</h1>
                <p>Connecting You to Solutions.</p>
            </div>
        </div>
    </div>
    <div class="contentContainer">
        <Services />
    </div>
    <Subscribe />
    <div class="contentContainer">
        <Map />
        <Footer />
    </div>
</div>
</template>

<script>
export default {
    name: "services"
}
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
</style>
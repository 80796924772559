<template>
<div class="overlay">
    <form class="form" @submit.prevent="submitform()">
        <div class="top">
            <h3 class="title">{{ modalform.type }} Hosting</h3>
            <div class="close" @click="close()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
        </div>
        <div class="form-inputs" v-if="(modalform.type == 'add' || modalform.type == 'edit') && form && modalform.form == 'active'">
            <label>Project type</label>
            <select v-model="form.project_type" required>
                <option value="" selected hidden>Select Project Type</option>
                <option value="personal">Personal</option>
                <option value="company">Company</option>
            </select>
            <div class="flex" v-if="form.project_type === 'company'">
                <div>
                    <label>Company name</label>
                    <input type="text" placeholder="Enter company name" v-model="form.company_name">
                </div>
                <div>
                    <label>Company email</label>
                    <input type="email" placeholder="Enter company email" v-model="form.company_email" required>
                </div>
            </div>
            <div class="flex" v-if="form.project_type === 'company'">
                <div>
                    <label>Company phone</label>
                    <input type="text" placeholder="Enter company phone" v-model="form.company_phone">
                </div>
                <div>
                    <label>Company address</label>
                    <input type="text" placeholder="Enter company address" v-model="form.company_address">
                </div>
            </div>
            <label v-if="form.project_type === 'company'">Position</label>
            <input v-if="form.project_type === 'company'" placeholder="Enter person position" type="text" v-model="form.position">
            <div class="flex" v-if="form.project_type === 'personal' || form.project_type === 'company'">
                <div>
                    <label>Focal Person Name</label>
                    <input type="text" placeholder="Enter names" v-model="form.person_name">
                </div>
                <div>
                    <label>Focal Person Address</label>
                    <input type="text" placeholder="Enter address" v-model="form.person_address">
                </div>
            </div>
            <div class="flex" v-if="form.project_type === 'personal' || form.project_type === 'company'">
                <div>
                    <label>Focal Person Email</label>
                    <input type="email" placeholder="Enter email" v-model="form.person_email" required>
                </div>
                <div>
                    <label>Focal Person Phone</label>
                    <input type="text" placeholder="Enter phone" v-model="form.person_phone">
                </div>
            </div>
            <label>Project name</label>
            <input type="text" placeholder="Enter project name" v-model="form.name" required>
            <label>Domain name</label>
            <input type="text" placeholder="Enter domain name" v-model="form.domain" required>
            <label>Hostin plan</label>
            <select v-model="form.hosting_id" required>
                <option value="" selected>Select Hosting plan</option>
                <option :value="plan.id" v-for="plan in plans" :key="plan.id">{{ plan.name }} (${{ plan.price }})</option>
                <option :value="0">Custom</option>
            </select>
            <div v-if="form.hosting_id === 0">
                <label>Hosting fees periods</label>
                <input type="text" placeholder="Enter hosting fees" v-model="form.hosting_fees" >
            </div>
            <label>Expiry Date</label>
            <input type="date" v-model="form.domain_exp">
            <label>Hosting Status</label>
            <select v-model="form.status" required>
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                <option value="On Hold">On Hold</option>
                <option value="Testing">Testing</option>
                <option value="Completed">Completed</option>
                <option value="Delivered">Delivered</option>
            </select>
            <label>Hosting description</label>
            <textarea v-model="form.description" placeholder="Write a description" rows="4" required></textarea>
        </div>
        <div class="form-inputs" v-else-if="(modalform.type == 'add' || modalform.type == 'edit') && form && modalform.form == 'plan'">
            <label>Hosting Name</label>
            <input type="text" v-model="form.name" placeholder="Enter plan name" required>
            <label>Price</label>
            <input type="number" v-model="form.price" placeholder="Enter price" required>
            <label>Host attributes</label>
            <textarea v-model="form.attributes" placeholder="Example: 40GB Bandwidth, 2 Subdomains, ..." rows="4" required></textarea>
            <label>Visibility</label>
            <select v-model="form.visibility" required>
                <option value="" disabled selected>Select visibility</option>
                <option value="public">Public</option>
                <option value="unlisted">Unlisted</option>
            </select>
        </div>
        <div class="form-inputs" v-else-if="modalform.type == 'view' && form && modalform.form == 'plan'">
            <label>Hosting plan name</label>
            <p>{{ form.name }}</p>
            <label>Price</label>
            <p>${{ form.price }}</p>
            <label>Host attributes</label>
            <p v-for="(attribute,index) in form.attributes" :key="index">- {{ attribute }}</p>
            <label>Visibility</label>
            <p>{{ form.visibility }}</p>
        </div>
        <label class="warn-text" v-if="modalform.type == 'delete' || modalform.type == 'approve' || modalform.type == 'decline'">Are you sure you want to {{ modalform.type }} this hosting ?</label>
        <button v-if="modalform.type != 'view'">{{ modalform.type }} Hosting</button>
    </form>
</div>
</template>

<script>
export default {
    name: "ProposalModal",
    props: {
        current: {
            type: Object,
            default: null
        },
        modalform: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            services: [],
            plans: []
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        handelform(){
            this.form = this.current
            if(this.form.hosting_id == null) this.form.hosting_id = 0;
        },
        submitform(){
            this.loading = true
            if(this.modalform.type == 'add' && this.modalform.form == 'plan'){
                this.form.attributes = JSON.stringify(this.form.attributes.split(/,\s*/))
                this.$store.dispatch('CREATE_HOSTING',this.form)
                    .then(res => {
                        if(res.data.message == "Hosting created successfully"){
                            this.$notify({
                                text: res.data.message,
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'plan')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'add' && this.modalform.form == 'active'){
                this.form.hosting_id == 0 ? this.form.hosting_id = null : this.form.hosting_fees = null;
                this.$store.dispatch('CREATE_PROJECT_REQUEST',this.form)
                    .then(res => {
                        if(res.data.message == "Project created successfully"){
                            this.$notify({
                                text: res.data.message,
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'active')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'edit' && this.modalform.form == 'plan'){
                this.$store.dispatch('UPDATE_HOSTING',this.form)
                    .then(res => {
                        if(res.data.message == "Hosting updated successfully"){
                            this.$notify({
                                text: res.data.message,
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'plan')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'edit' && this.modalform.form == 'active'){
                this.form.hosting_id == 0 ? this.form.hosting_id = null : this.form.hosting_fees = null;
                this.$store.dispatch('UPDATE_PROJECT_REQUEST',this.form)
                    .then(res => {
                        if(res.data.message == "Project updated successfully"){
                            this.$notify({
                                text: "Hosting updated successfully",
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'active')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'delete' && this.modalform.form == 'plan'){
                this.$store.dispatch('DELETE_HOSTING',this.form.id)
                    .then(res => {
                        if(res.data.message == "Hosting deleted successfully"){
                            this.$notify({
                                text: res.data.message,
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'plan')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'delete' && this.modalform.form == 'active'){
                this.$store.dispatch('DELETE_PROJECT_REQUEST',this.form.id)
                    .then(res => {
                        if(res.data.message == "Project deleted successfully"){
                            this.$notify({
                                text: "Hosting deleted successfully",
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'active')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'approve'){
                this.$store.dispatch('APPROVE_HOSTING',this.form.id)
                    .then(res => {
                        if(res.data.message == "Project approved successfully"){
                            this.$notify({
                                text: 'Hosting approved successfully',
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'all')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.modalform.type == 'decline'){
                this.$store.dispatch('DECLINE_HOSTING',this.form.id)
                    .then(res => {
                        if(res.data.message == "Project declined successfully"){
                            this.$notify({
                                text: 'Hosting declined successfully',
                                type: "success"
                            })
                            this.loading = false
                            this.$emit("close")
                            this.$emit('refresh', 'request')
                        } else {
                            this.$notify({
                                text: res.data.message,
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    },
    mounted() {
        this.handelform()
        this.$getAllHostingPlans()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/main.scss";

</style>
<template>
  <div class="dashboardContent">
    <Sidebar />
    <div class="content">
        <Topbar />
        <Loader v-if="loading" />
        <Modal v-if="modal" :current="form" :modalform="modalform" @close="closeModal()" @refresh="getProjects" />
        <div class="detailed-content">
            <div class="locator-search">
                <h1 class="locator">Projects</h1>
                <div class="end">
                    <div class="search">
                        <input type="text" placeholder="Search for projects">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_15_152)"> <rect width="24" height="24" fill="white"></rect> <circle cx="10.5" cy="10.5" r="6.5" stroke="#ccc" stroke-linejoin="round"></circle> <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#000000"></path> </g> <defs> <clipPath id="clip0_15_152"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                    </div>
                    <button class="btn-add" @click="openModal('add',null, modalform)">Add project</button>
                </div>
            </div>
            <div class="tabs">
                <label class="tab" @click="tab = 'active'" :class="{ active: tab == 'active' }">Active</label>
                <label class="tab" @click="tab = 'pending'" :class="{ active: tab == 'pending' }">Pending / Requested</label>
            </div>
            <div class="active" v-if="tab == 'active'">
                <div class="table" v-if="projects.length > 0">
                    <div class="headings">
                        <h3 class="heading">Project Name</h3>
                        <h3 class="heading">Start Date</h3>
                        <h3 class="heading">Delivery Date</h3>
                        <h3 class="heading">Status</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="project" v-for="project in projects" :key="project.id">
                        <label class="data hover" @click="$router.push(`/admin/projects/${project.id}/${hyphenName(project.name)}`)">
                            {{ project.name }}
                        </label>
                        <label class="data" v-if="project.start_date">{{ project.start_date }}</label>
                        <label class="data" v-else>N/A</label>
                        <label class="data" v-if="project.delivery_date">{{ project.delivery_date }}</label>
                        <label class="data" v-else>N/A</label>
                        <label class="data">{{ project.status }}</label>
                        <div class="data actions">
                            <svg @click="toggledrop(project)" fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M899.4 638.2h-27.198c-2.2-.6-4.2-1.6-6.4-2-57.2-8.8-102.4-56.4-106.2-112.199-4.401-62.4 31.199-115.2 89.199-132.4 7.6-2.2 15.6-3.8 23.399-5.8h27.2c1.8.6 3.4 1.6 5.4 1.8 52.8 8.6 93 46.6 104.4 98.6.8 4 2 8 3 12v27.2c-.6 1.8-1.6 3.6-1.8 5.4-8.4 52-45.4 91.599-96.801 103.6-5 1.2-9.6 2.6-14.2 3.8zM130.603 385.8l27.202.001c2.2.6 4.2 1.6 6.4 1.8 57.6 9 102.6 56.8 106.2 113.2 4 62.2-32 114.8-90.2 131.8-7.401 2.2-15 3.8-22.401 5.6h-27.2c-1.8-.6-3.4-1.6-5.2-2-52-9.6-86-39.8-102.2-90.2-2.2-6.6-3.4-13.6-5.2-20.4v-27.2c.6-1.8 1.6-3.6 1.8-5.4 8.6-52.2 45.4-91.6 96.8-103.6 4.8-1.201 9.4-2.401 13.999-3.601zm370.801.001h27.2c2.2.6 4.2 1.6 6.4 2 57.4 9 103.6 58.6 106 114.6 2.8 63-35.2 116.4-93.8 131.4-6.2 1.6-12.4 3-18.6 4.4h-27.2c-2.2-.6-4.2-1.6-6.4-2-57.4-8.8-103.601-58.6-106.2-114.6-3-63 35.2-116.4 93.8-131.4 6.4-1.6 12.6-3 18.8-4.4z"></path></g></svg>
                            <div class="all-options" :class="{ active: project.ondrop}">
                                <button class="btn" @click="$router.push(`/admin/projects/${project.id}/${hyphenName(project.name)}`)">View</button>
                                <button class="btn" @click="openModal('edit', project, 'request')">Edit</button>
                                <button class="btn del" @click="openModal('delete', project, 'request')">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No projects available</label>
            </div>
            <div class="active" v-if="tab == 'pending'">
                <div class="table" v-if="requests.length > 0">
                    <div class="headings requests">
                        <h3 class="heading">Project Name</h3>
                        <h3 class="heading">Type</h3>
                        <h3 class="heading">Description</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="project requests" v-for="project in requests" :key="project.id">
                        <label class="data">{{ project.name }}</label>
                        <label class="data">{{ project.service.name }}</label>
                        <label class="data">{{ project.description }}</label>
                        <div class="data actions">
                            <svg @click="toggledrop(project)" fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M899.4 638.2h-27.198c-2.2-.6-4.2-1.6-6.4-2-57.2-8.8-102.4-56.4-106.2-112.199-4.401-62.4 31.199-115.2 89.199-132.4 7.6-2.2 15.6-3.8 23.399-5.8h27.2c1.8.6 3.4 1.6 5.4 1.8 52.8 8.6 93 46.6 104.4 98.6.8 4 2 8 3 12v27.2c-.6 1.8-1.6 3.6-1.8 5.4-8.4 52-45.4 91.599-96.801 103.6-5 1.2-9.6 2.6-14.2 3.8zM130.603 385.8l27.202.001c2.2.6 4.2 1.6 6.4 1.8 57.6 9 102.6 56.8 106.2 113.2 4 62.2-32 114.8-90.2 131.8-7.401 2.2-15 3.8-22.401 5.6h-27.2c-1.8-.6-3.4-1.6-5.2-2-52-9.6-86-39.8-102.2-90.2-2.2-6.6-3.4-13.6-5.2-20.4v-27.2c.6-1.8 1.6-3.6 1.8-5.4 8.6-52.2 45.4-91.6 96.8-103.6 4.8-1.201 9.4-2.401 13.999-3.601zm370.801.001h27.2c2.2.6 4.2 1.6 6.4 2 57.4 9 103.6 58.6 106 114.6 2.8 63-35.2 116.4-93.8 131.4-6.2 1.6-12.4 3-18.6 4.4h-27.2c-2.2-.6-4.2-1.6-6.4-2-57.4-8.8-103.601-58.6-106.2-114.6-3-63 35.2-116.4 93.8-131.4 6.4-1.6 12.6-3 18.8-4.4z"></path></g></svg>
                            <div class="all-options" :class="{ active: project.ondrop}">
                                <button class="btn" @click="$router.push(`/admin/projects/${project.id}/${hyphenName(project.name)}`)">View</button>
                            </div>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No requests sent</label>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Modal from './components/ProposalModal.vue';
export default {
    name: "AdminProject",
    components: { Modal },
    data() {
        return {
            loading: false,
            modal: false,
            modalform: null,
            tab: 'active',
            form: {
                name: "",
                type: "",
                description: "",
                start_date: "",
                service_id: "",
                development_cost: "",
                user_id: "",
                project_type: "",
                company_name: "",
                company_email: "",
                company_phone: "",
                company_address: "",
                personal_contact: "",
                delivery_date: "",
                start_date: "",
                domain: "",
                domain_exp: "",
                hosting_fees: "",
                person_name: "",
                position: "",
                person_name: "",
                personal_contact: "",
                status: ""
            },
            requests: [],
            projects: [],
        }
    },
    methods:{
        closeModal(){
            this.modal = false;
            this.modalform = null;
            this.projects.forEach(project => { project.ondrop = false; });
            this.form = { name: "", type: "", description: "", start_date: "", service_id: "", development_cost: "", user_id: "", project_type: "", company_name: "", company_email: "", company_phone: "", company_address: "", personal_contact: "", person_name: "", position: "", person_name: "", person_email: "", personal_contact: "", status: "" }
        },
        openModal(type, content, modalform){
            this.modal = true;
            this.modalform = { type: type, form: modalform };
            type != 'add' ? { ...this.form } = content : ''
        },
        toggledrop(project){
            this.projects.forEach(project => {
                if(project.id != project.id) project.ondrop = false;
            });
            project.ondrop = !project.ondrop;
        },
        hyphenName(name){
            return name.replace(/\s+/g, '-').toLowerCase();
        },
        getProjects(type){
            if(type == 'all'){
                this.getAllProjects();
                this.getAllProjectsRequests();
            } else if(type == 'request'){
                this.getAllProjectsRequests();
            } else if(type == 'active') {
                this.getAllProjects();
            }
        },
        getAllProjectsRequests(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_PROJECTS_REQUESTS')
                .then(res => {
                    this.loading = false;
                    this.requests = res.data;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        },
        getAllProjects(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_PROJECTS')
                .then(res => {
                    this.loading = false;
                    res.data.forEach(project => {
                        project.ondrop = false;
                    });
                    this.projects = res.data;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        }
    },
    mounted() {
        this.getAllProjects();
        this.getAllProjectsRequests();
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/main.scss";
.detailed-content{
    padding: 20px;
    .tabs{
        display: flex;
        align-items: center;
        margin: 20px 0px;
        column-gap: 30px;
        .tab{
            font-size: 0.8rem;
            font-weight: 500;
            color: #333;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            &:hover{
                background-color: #f1f1f1;
            }
            &.active{
                background-color: #f5a62361;
                color: #905b06;
            }
        }
    }
    .table{
        margin: 20px;
        .headings{
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
            column-gap: 10px;
            padding: 10px 0;
            margin: 0 0 10px;
            border-bottom: 1px solid #dbdbdb8b;
            &.requests{
                grid-template-columns: 0.8fr 1fr 1.5fr 1.5fr;
            }
            .heading{
                font-size: 0.8rem;
                font-weight: 500;
                color: #333;
            }
        }
        .project{
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
            align-items: center;
            column-gap: 10px;
            margin: 0 0 15px;
            padding: 10px 0;
            transition: .6s;
            border-bottom: 1px solid #dbdbdb54;
            // &:hover{
            //     transform: scale(1.01);
            // }
            &.requests{
                grid-template-columns: 0.8fr 1fr 1.5fr 1.5fr;
            }
            .data{
                font-size: 0.8rem;
                color: #333;
                height: fit-content;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                transition: .2s;
                &.hover{
                    cursor: pointer;
                    &:hover{
                        color: #f5a623;
                        text-decoration: underline;
                    }
                }
                &.img{
                    img{
                        width: 100px;
                        height: 60px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                }
                &.link{
                    a{
                        svg{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &.status{
                    width: fit-content;
                    padding: 3px 10px;
                    border-radius: 4px;
                    &.completed{
                        background: #0984e361;
                        color: #0984e3;
                    }
                    &.ongoing{
                        background: #f5235f61;
                        color: #d61d61;
                    }
                }
                &.actions{
                    all: unset;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        transition: .6s;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    .all-options{
                        position: absolute;
                        top: 150%;
                        display: flex;
                        flex-direction: column;
                        column-gap: 10px;
                        overflow: hidden;
                        border-radius: 4px;
                        box-shadow: 0px 5px 30px -10px #0000001a;
                        transition: .6s;
                        opacity: 0;
                        pointer-events: none;
                        z-index: 99;
                        &.active{
                            top: 120%;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    button{
                        padding: 5px 20px;
                        cursor: pointer;
                        border: none;
                        background-color: #f3f3f3;
                        font-size: 0.7rem;
                        font-weight: 500;
                        color: #333;
                        cursor: pointer;
                        transition: .6s;
                        &:hover{
                            background-color: #ccc;
                        }
                        &.del{
                            background-color: salmon;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>
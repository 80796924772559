import axios from 'axios';
import { APPROVE_PROJECT_REQUEST, CREATE_FEEDBACK_UPDATE, CREATE_PORTFOLIO, CREATE_PROJECT_REQUEST, DECLINE_PROJECT_REQUEST, DELETE_FEEDBACK_UPDATE, DELETE_PORTFOLIO, DELETE_PROJECT_REQUEST, GET_ALL_PORTFOLIO, GET_ALL_PROJECTS, GET_ALL_PROJECTS_REQUESTS, GET_ONE_PROJECT, UPDATE_FEEDBACK_UPDATE, UPDATE_PORTFOLIO, UPDATE_PROJECT_REQUEST } from '../actions/projects';
import { APPROVE_PROJECT_REQUEST_URL, CREATE_FEEDBACK_UPDATE_URL, CREATE_PORTFOLIO_URL, CREATE_PROJECT_REQUEST_URL, DECLINE_PROJECT_REQUEST_URL, DELETE_FEEDBACK_UPDATE_URL, DELETE_PORTFOLIO_URL, DELETE_PROJECT_REQUEST_URL, GET_ALL_PORTFOLIO_URL, GET_ALL_PROJECTS_REQUESTS_URL, GET_ALL_PROJECTS_URL, GET_ONE_PROJECT_URL, UPDATE_FEEDBACK_UPDATE_URL, UPDATE_PORTFOLIO_URL, UPDATE_PROJECT_REQUEST_URL } from '../variables';

const projects = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_PORTFOLIO]: ({ commit, state, rootState, rootGetters }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_PORTFOLIO_URL
            })
        },
        [GET_ONE_PROJECT]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ONE_PROJECT_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [CREATE_PORTFOLIO]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_PORTFOLIO_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [UPDATE_PORTFOLIO]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_PORTFOLIO_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [DELETE_PORTFOLIO]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_PORTFOLIO_URL + payload,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [GET_ALL_PROJECTS]: ({ commit, state, rootState, rootGetters }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_PROJECTS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [GET_ALL_PROJECTS_REQUESTS]: ({ commit, state, rootState, rootGetters }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_PROJECTS_REQUESTS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [CREATE_PROJECT_REQUEST]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_PROJECT_REQUEST_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [UPDATE_PROJECT_REQUEST]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_PROJECT_REQUEST_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [DELETE_PROJECT_REQUEST]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_PROJECT_REQUEST_URL + payload,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [APPROVE_PROJECT_REQUEST]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + APPROVE_PROJECT_REQUEST_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        [DECLINE_PROJECT_REQUEST]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + DECLINE_PROJECT_REQUEST_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        },
        // Project Feedbacks and Updates
        [CREATE_FEEDBACK_UPDATE]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_FEEDBACK_UPDATE_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_FEEDBACK_UPDATE]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_FEEDBACK_UPDATE_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_FEEDBACK_UPDATE]: ({ commit, state, rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_FEEDBACK_UPDATE_URL + payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
    }
}

export default projects;
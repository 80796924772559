<template>
  <router-view />
</template>
<script>
export default {
  name: "AdminDashboard",
  methods: {
    checkAuth() {
      if(!this.$loggedAdmin()) {
        this.$router.push("/admin");
      }
    }
  },
  mounted() {
    this.checkAuth();
  }
}
</script>

<style>

</style>
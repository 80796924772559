<template>
    <div class="container login">
        <form class="form" @submit.prevent="signin()">
        <div class="top">
            <div class="logo">
                <img src="@/assets/logo.png" alt="ireme-logo" draggable="false">
            </div>
            <!-- <h3>Ireme Technologies | Admin</h3> -->
        </div>
        <div class="inputs">
            <label>Email</label>
            <input type="email"  v-model="signinForm.email" placeholder="Enter your email" required>
            <label>Password</label>
            <input type="password" v-model="signinForm.password" placeholder="Enter your password" required>
            <button>
            <!-- <TinyLoader v-if="loading" /> -->
                Sign in
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26"><path d="M13.001953 0C12.486953 0 11.941953 0.18121875 11.751953 0.44921875C11.621953 0.63021875 11.25 10 11.25 10L9.5117188 10C9.3227187 10 9.1505469 10.104531 9.0605469 10.269531C8.9715469 10.435531 8.9809375 10.636969 9.0859375 10.792969C10.454937 12.813969 12.590641 14.819625 12.681641 14.890625C12.775641 14.964625 12.888 15 13 15C13.113 15 13.226359 14.962625 13.318359 14.890625C13.408359 14.818625 15.545063 12.813969 16.914062 10.792969C17.020063 10.635969 17.028453 10.434531 16.939453 10.269531C16.850453 10.103531 16.676328 10 16.486328 10L14.75 10C14.75 10 14.455172 0.63121875 14.326172 0.44921875C14.135172 0.18121875 13.517953 0 13.001953 0 z M 9.5742188 2.5058594C4.6222187 3.9838594 1.0097656 8.573 1.0097656 14C1.0097656 20.617 6.383 26 13 26C19.617 26 25 20.617 25 14C25 8.595 21.406375 4.0165312 16.484375 2.5195312C16.512375 3.1015313 16.542219 3.820875 16.574219 4.671875C20.326219 6.113875 23 9.747 23 14C23 19.514 18.514 24 13 24C7.486 24 3 19.514 3 14C3 9.731 5.69475 6.08725 9.46875 4.65625C9.50675 3.80525 9.5412187 3.0878594 9.5742188 2.5058594 z"/></svg>
            </button>
        </div>
        </form>
    </div>
</template>
<script>
export default {
    name: "AdminHome",
    data() {
        return {
            loading: false,
            signinForm: {
                email: "",
                password: "",
            },
        }
    },
    methods:{
        signin(){
            this.$store.dispatch('ADMIN_LOGIN', this.signinForm)
                .then(resp => {
                    this.$notify({
                        text: `${resp.data.message}`,
                        type: 'success'
                    })
                    localStorage.setItem('AdminToken', resp.data.admin_token)
                    localStorage.setItem('loggedAdmin', JSON.stringify(resp.data.data))
                    this.$router.push('/admin/dashboard')
                    this.signinForm = {
                        email: '',
                        password: ''
                    }
                    this.loading = false;
                    location.reload();
                })
                .catch(err => {
                    this.loading = false;
                    if(err.response.data.message){
                        this.$notify({
                            text: `${err.response.data.message}`,
                            type: 'error'
                        })
                    }else{
                        for (const key in err.response.data.errors) {
                            if (errors.hasOwnProperty(key)) {
                                errors[key].forEach(errorMsg => {
                                    this.$notify({
                                        text: `${errorMsg}`,
                                        type: 'error'
                                    })
                                });
                            }
                        }
                    }
                })
        },
        checkAuth() {
            if (this.$loggedAdmin()) {
                this.$router.push("/admin/dashboard");
            }
        }
    },
    mounted() {
        this.checkAuth();
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/main.scss";
.container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form{
    width: 500px;
    background: $white;
    border-radius: 10px;
    padding: 15px 0;
    .top{
        padding: 5px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 7px;
        text-align: center;
        align-items: center;
        justify-content: center;
        .logo{
            width: 170px;
            height: 40px;
            position: relative;
            img{
                position: absolute;
                left: 0;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            color: $realblack;
        }
    }
    .inputs{
        padding: 20px 30px;
        width: 100%;
        transition: .8s;
        .flex{
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }
        label{
            display: block;
            font-size: 0.85rem;
            color: $realblack;
        }
        input,textarea{
            outline: none;
            resize: none;
            display: block;
            margin: 10px 0;
            padding: 7px 10px;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 0.8rem;
        }
        button{
            outline: none;
            margin: 20px 0 15px 0;
            display: flex;
            align-items: center;
            padding: 7px 20px;
            cursor: pointer;
            border-radius: 4px;
            font-size: 0.8rem;
            column-gap: 15px;
            background: $darkorange;
            position: relative;
            overflow: hidden;
            border: none; 
            width: 100%;
            justify-content: center;
            color: #fff;
            white-space: nowrap;
            svg{
                fill: #fff;
                width: 20px;
                height: 20px;
                transform: scaleY(-1) rotate(-90deg);
                &.enquiry{
                    transform: none;
                }
            }
        }
    }
}
</style>

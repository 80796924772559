import axios from 'axios'
import { ADD_BLOG_COMMENT, CREATE_BLOG, DELETE_BLOG, GET_ALL_BLOGS, GET_SINGLE_BLOG, UPDATE_BLOG } from '../actions/blogs'
import { ADD_BLOG_COMMENT_URL, CREATE_BLOG_URL, DELETE_BLOG_URL, GET_ALL_BLOGS_URL, GET_SINGLE_BLOG_URL, UPDATE_BLOG_URL } from '../variables'

const blogs = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_BLOGS]: ({ commit, dispatch, rootState, rootGetters }) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_BLOGS_URL
            })
        },
        [GET_SINGLE_BLOG]: ({ commit, dispatch, rootState, rootGetters }, payload) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_SINGLE_BLOG_URL + payload
            })
        },
        [CREATE_BLOG]: ({ commit, dispatch, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_BLOG_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [UPDATE_BLOG]: ({ commit, dispatch, rootState, rootGetters }, payload) => {
            return axios({
                method: 'PUT',
                url: rootState.apiBaseUrl + UPDATE_BLOG_URL + payload.id,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_BLOG]: ({ commit, dispatch, rootState, rootGetters }, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_BLOG_URL + payload,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [ADD_BLOG_COMMENT]: ({ commit, dispatch, rootState, rootGetters }, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + ADD_BLOG_COMMENT_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token,
                }
            })
        }
    }
}

export default blogs
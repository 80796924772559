export const GET_ALL_PORTFOLIO = "GET_ALL_PORTFOLIO";
export const CREATE_PORTFOLIO = "CREATE_PORTFOLIO";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ONE_PROJECT = "GET_ONE_PROJECT";
export const GET_ALL_PROJECTS_REQUESTS = "GET_ALL_PROJECTS_REQUESTS";
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const APPROVE_PROJECT_REQUEST = "APPROVE_PROJECT_REQUEST";
export const DECLINE_PROJECT_REQUEST = "DECLINE_PROJECT_REQUEST";

export const CREATE_FEEDBACK_UPDATE = "CREATE_FEEDBACK_UPDATE";
export const DELETE_FEEDBACK_UPDATE = "DELETE_FEEDBACK_UPDATE";
export const UPDATE_FEEDBACK_UPDATE = "UPDATE_FEEDBACK_UPDATE";
<template>
  <div class="dashboardContent">
    <Sidebar />
    <div class="content">
        <Topbar />
        <Loader v-if="loading" />
        <DocumentModal v-if="documentmodal" :type="type" :current="dynamicId" @close="closeModal()" @refresh="getProject" />
        <UpdateModal v-if="updatemodal" :type="type" :current="data" @close="closeModal()" @refresh="getProject" />
        <ProposalModal v-if="requestmodal" :modalform="{ type: type }" :current="{ id: project.id }" @close="closeModal()" @refresh="getProject" />
        <div class="detailed-content">
            <div class="locator-search">
                <h1 class="locator">Domain Details</h1>
                <div class="end">
                    <button v-if="project.status !== 'Pending'" class="btn-add" @click="openModal('add', project.id, 'document')">Attach Document</button>
                    <button class="btn-add" @click="openModal('add', null, 'update')">{{ project.status == 'Pending' ?  'Reply on proposal' : 'Add An Update' }}</button>
                    <button v-if="project.status == 'Pending'" class="btn-add" @click="openModal('approve', project.id, 'request')">Approve Project</button>
                    <button v-if="project.status == 'Pending'" class="btn-add decline" @click="openModal('decline', project.id, 'request')">Decline Projct</button>
                </div>
            </div>
            <div class="details" v-if="project">
                <div class="project-company-user-info">
                    <div class="project-info">
                        <h3 class="heading">Domain Information</h3>
                        <div class="all-info">
                            <div class="info">
                                <h4 class="title">Domain name</h4>
                                <p class="value" v-if="project.domain">{{ project.domain }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Domain Status</h4>
                                <p class="value" v-if="project.status">{{ project.status }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Hosting Plan</h4>
                                <p class="value" v-if="project.hosting">{{ project.hosting.name }} ({{ project.hosting.price }}$)</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Domain Expiry date</h4>
                                <p class="value" v-if="project.domain_exp">{{ project.domain_exp }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Re-Hosting</h4>
                                <p class="value" v-if="project.hosting_fees">{{ project.hosting_fees }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Project Description</h4>
                                <p class="value" v-if="project.description">{{ project.description }}</p>
                                <p class="value" v-else>...</p>
                            </div>
                        </div>
                    </div>
                    <div class="company-info" v-if="project.project_type == 'company'">
                        <h3 class="heading">Company Information</h3>
                        <div class="all-info">
                            <div class="info">
                                <h4 class="title">Company Name</h4>
                                <p class="value" v-if="project.company_name">{{ project.company_name }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Company Email</h4>
                                <p class="value" v-if="project.company_email">{{ project.company_email }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Company Phone</h4>
                                <p class="value" v-if="project.company_phone">{{ project.company_phone }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Company Address</h4>
                                <p class="value" v-if="project.company_address">{{ project.company_address }}</p>
                                <p v-else>N/A</p>
                            </div>
                        </div>
                    </div>
                    <div class="focal-person-info" v-if="project.project_type == 'company' ||  project.project_type == 'personal'">
                        <h3 class="heading">Focal Person Information</h3>
                        <div class="all-info">
                            <div class="info">
                                <h4 class="title">Focal Person Name</h4>
                                <p class="value" v-if="project.person_name">{{ project.person_name }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Focal Person Email</h4>
                                <p class="value" v-if="project.person_email">{{ project.person_email }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Focal Person Phone</h4>
                                <p class="value" v-if="project.personal_contact">{{ project.personal_contact }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                            <div class="info">
                                <h4 class="title">Focal Person Position</h4>
                                <p class="value" v-if="project.position">{{ project.position }}</p>
                                <p class="value" v-else>N/A</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="updates-documents">
                    <div class="updates">
                        <h3 class="heading">Project Feedbacks & Updates</h3>
                        <div class="table">
                            <div class="headings">
                                <h3>Feedback/Update</h3>
                                <h3>Date</h3>
                                <h3></h3>
                            </div>
                            <div class="row" :class="{ update: feed.type == 'update' }" v-for="feed in project.feedback_updates" :key="feed.id">
                                <label>{{ feed.message }} </label>
                                <label>{{ feed.date }}</label>
                                <div class="actions" v-if="feed.type == 'update'">
                                    <button class="edit" @click="openModal('edit', feed, 'update')"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.1498 7.93997L8.27978 19.81C7.21978 20.88 4.04977 21.3699 3.32977 20.6599C2.60977 19.9499 3.11978 16.78 4.17978 15.71L16.0498 3.84C16.5979 3.31801 17.3283 3.03097 18.0851 3.04019C18.842 3.04942 19.5652 3.35418 20.1004 3.88938C20.6356 4.42457 20.9403 5.14781 20.9496 5.90463C20.9588 6.66146 20.6718 7.39189 20.1498 7.93997V7.93997Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                                    <button class="delete" @click="openModal('delete', feed, 'update')"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 6.98996C8.81444 4.87965 15.1856 4.87965 21 6.98996" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.00977 5.71997C8.00977 4.6591 8.43119 3.64175 9.18134 2.8916C9.93148 2.14146 10.9489 1.71997 12.0098 1.71997C13.0706 1.71997 14.0881 2.14146 14.8382 2.8916C15.5883 3.64175 16.0098 4.6591 16.0098 5.71997" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13V18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M19 9.98999L18.33 17.99C18.2225 19.071 17.7225 20.0751 16.9246 20.8123C16.1266 21.5494 15.0861 21.9684 14 21.99H10C8.91389 21.9684 7.87336 21.5494 7.07541 20.8123C6.27745 20.0751 5.77745 19.071 5.67001 17.99L5 9.98999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="documents" v-if="project.status != 'Pending'">
                        <h3 class="heading">Domain Documents</h3>
                        <div class="table">
                            <div class="headings documents">
                                <h3>Name</h3>
                                <h3>Type</h3>
                                <h3>Uploaded date</h3>
                                <h3></h3>
                            </div>
                            <div class="row documents" v-for="document in project.documents" :key="document.id">
                                <label>{{ document.file_old_name }}</label>
                                <label>{{ document.file_type }}</label>
                                <label>{{ document.date }}</label>
                                <div class="actions">
                                    <a :href="$file(document.file_name)" target="_blank" :download="document.file_old_name">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path class="st0" d="M230.546,324.601c6.688,6.703,15.969,10.547,25.454,10.547c9.469,0,18.75-3.844,25.453-10.547L398.422,207.64 c14.062-14.054,14.062-36.851,0-50.906c-14.062-14.062-36.859-14.062-50.906,0v-0.007L292,212.242V38.188c0-19.883-16.125-36-36-36 c-19.89,0-36,16.117-36,36v174.046l-55.5-55.5c-14.062-14.062-36.859-14.062-50.906-0.007c-14.062,14.062-14.062,36.859,0,50.914 L230.546,324.601z"></path> <path class="st0" d="M473.453,383.148H333.406c-13.36,29.469-42.954,50-77.406,50c-34.454,0-64.047-20.531-77.39-50H38.562 C17.266,383.148,0,400.406,0,421.696v49.562c0,21.297,17.266,38.554,38.562,38.554h434.89c21.297,0,38.547-17.258,38.547-38.554 v-49.562C512,400.406,494.75,383.148,473.453,383.148z"></path> </g> </g></svg>
                                    </a>
                                    <button class="delete" @click="openModal('delete', document.id, 'document')"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 6.98996C8.81444 4.87965 15.1856 4.87965 21 6.98996" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.00977 5.71997C8.00977 4.6591 8.43119 3.64175 9.18134 2.8916C9.93148 2.14146 10.9489 1.71997 12.0098 1.71997C13.0706 1.71997 14.0881 2.14146 14.8382 2.8916C15.5883 3.64175 16.0098 4.6591 16.0098 5.71997" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13V18" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M19 9.98999L18.33 17.99C18.2225 19.071 17.7225 20.0751 16.9246 20.8123C16.1266 21.5494 15.0861 21.9684 14 21.99H10C8.91389 21.9684 7.87336 21.5494 7.07541 20.8123C6.27745 20.0751 5.77745 19.071 5.67001 17.99L5 9.98999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import DocumentModal from './components/DocumentModal.vue';
import UpdateModal from './components/UpdateModal.vue';
import ProposalModal from '../Projects/components/ProposalModal.vue'
export default {
    name: "AdminProjectDetails",
    components: { DocumentModal, UpdateModal, ProposalModal },
    data() {
        return {
            loading: false,
            documentmodal: false,
            updatemodal: false,
            requestmodal: false,
            type: null,
            project: [],
            dynamicId: null,
            data: {
                message: '',
                project_id: null,
                user_id: this.$loggedAdmin().id
            },
        }
    },
    methods:{
        closeModal(){
            this.modal = false;
            this.modalform = null;
            this.documentmodal = false;
            this.updatemodal = false;
            this.requestmodal = false;
            this.data = { message: '', project_id: null, user_id: this.$loggedAdmin().id };
        },
        openModal(type, content, modalform){
            this.type = type;
            if(modalform == 'document') { 
                this.dynamicId = content;
                this.documentmodal = true;
            } else if(modalform == 'update') {
                if(type == 'edit' || type == 'delete') this.data = content;
                this.data.project_id = this.project.id;
                this.updatemodal = true;
            } else if(modalform == 'request') {
                this.requestmodal = true;
            }
        },
        getProject(type = null){
            if(type == 'approved' || type == 'declined'){
                this.$router.push('/admin/projects');
                return;
            }
            this.loading = true;
            this.$store.dispatch('GET_ONE_PROJECT', this.$route.params.id)
            .then(res => {
                if(res.data.status == 'failed'){
                    this.$router.push('/admin/projects');
                    this.$notify({
                        group: 'error',
                        title: 'Error',
                        text: res.data.message,
                    });
                    return;
                }
                this.loading = false;
                this.project = res.data;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
    },
    mounted() {
        this.getProject();
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/main.scss";
.detailed-content{
    padding: 20px;
    .details{
        margin: 20px 0 0;
        .heading{
            font-size: 0.87rem;
            font-weight: 500;
            margin-bottom: 10px;
            padding: 0 0 3px;
            border-bottom: 1px solid #ccc;
        }
        .project-company-user-info{
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            column-gap: 5px;
            .project-info,.company-info,.focal-person-info{
                margin: 0 0 20px;
            }
            .all-info{
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 5px;
                .title{
                    display: block;
                    font-size: 0.77rem;
                    padding: 4px 10px;
                    border-radius: 4px;
                    background: #ececec;
                    color: #333;
                    font-weight: 500;
                }
                .value{
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: #838383;
                    line-height: 1.4rem;
                    margin: 4px 10px;
                    &::first-letter{
                        text-transform: capitalize;
                    }
                }
                .split{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
        .updates-documents{
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            .table{
                max-height: 600px;
                overflow-y: auto;
                padding: 0 5px 0 0;
                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                }
                &::-webkit-scrollbar-thumb {
                    background: $orange; 
                    border-radius: 5px;
                }
                .headings{
                    display: grid;
                    grid-template-columns: 2fr 0.8fr 0.8fr;
                    column-gap: 5px;
                    padding: 5px 10px;
                    background: #ececec;
                    border-radius: 5px;
                    &.documents{
                        grid-template-columns: 1.5fr 1fr 1fr 0.8fr;
                    }
                    h3{
                        font-size: 0.8rem;
                        font-weight: 500;
                    }
                }
                .row{
                    display: grid;
                    grid-template-columns: 2fr 0.8fr 0.8fr;
                    column-gap: 7px;
                    padding: 5px 10px;
                    margin: 5px 0;
                    border-bottom: 1px solid #ccc;
                    &.update{
                        // background: #f2f2f2;
                        border-bottom: 1px solid #ececec;
                    }
                    &.documents{
                        grid-template-columns: 1.5fr 1fr 1fr 0.8fr;
                    }
                    label{
                        font-size: 0.8rem;
                        font-weight: 300;
                        color: #838383;
                        line-height: 1.4rem;
                        margin: 4px 0;
                        &::first-letter{
                            text-transform: capitalize;
                        }
                    }
                    .actions{
                        display: flex;
                        column-gap: 8px;
                        a,button{
                            border: none;
                            cursor: pointer;
                            text-decoration: none;
                            outline: none;
                            border-radius: 4px;
                            background-color: #f5a62361;
                            cursor: pointer;
                            width: 30px;
                            height: 30px;
                            position: relative;
                            &.edit{
                                background-color: #f5a62361;
                                svg { fill: #905b06; stroke: none; }
                            }
                            svg{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 50%;
                                height: 50%;
                                margin-right: 5px;
                                fill: #905b06;
                            }
                        }
                        button{
                            background-color: #f52358;
                            svg{
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
import axios from 'axios'
import { CREATE_SUBSCRIPTION, DELETE_SUBSCRIBER, GET_ALL_SUBSCRIBERS } from '../actions/subscribers'
import { CREATE_SUBSCRIPTION_URL, DELETE_SUBSCRIBER_URL, GET_ALL_SUBSCRIBERS_URL } from '../variables'

const subscribers = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [GET_ALL_SUBSCRIBERS]: ({ commit, rootState, rootGetters}, payload) => {
            return axios({
                method: 'GET',
                url: rootState.apiBaseUrl + GET_ALL_SUBSCRIBERS_URL,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [CREATE_SUBSCRIPTION]: ({ commit, rootState, rootGetters}, payload) => {
            return axios({
                method: 'POST',
                url: rootState.apiBaseUrl + CREATE_SUBSCRIPTION_URL,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        },
        [DELETE_SUBSCRIBER]: ({ commit, rootState, rootGetters}, payload) => {
            return axios({
                method: 'DELETE',
                url: rootState.apiBaseUrl + DELETE_SUBSCRIBER_URL + payload,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.get_access_token
                }
            })
        }
    }
}

export default subscribers;
<template>
  <div class="dashboardContent">
    <Sidebar />
    <div class="content">
        <Topbar />
        <Loader v-if="loading" />
        <Modal v-if="modal" :current="form" :modalform="modalform" @close="closeModal()" @refresh="getHostings" />
        <div class="detailed-content">
            <div class="locator-search">
                <h1 class="locator">Hosting</h1>
                <div class="end">
                    <!-- <div class="search">
                        <input type="text" placeholder="Search for projects">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_15_152)"> <rect width="24" height="24" fill="white"></rect> <circle cx="10.5" cy="10.5" r="6.5" stroke="#ccc" stroke-linejoin="round"></circle> <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#000000"></path> </g> <defs> <clipPath id="clip0_15_152"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                    </div> -->
                    <select class="select" v-model="month" v-if="tab == 'active'">
                        <option value="" selected>All</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <button class="btn-add" v-if="tab == 'plan'" @click="openModal('add',null, 'plan')">Add hosting plan</button>
                    <button class="btn-add" v-if="tab == 'active'" @click="openModal('add',null, 'active')">Add a host</button>
                </div>
            </div>
            <div class="tabs">
                <label class="tab" @click="tab = 'plan'" :class="{ active: tab == 'plan' }">Plans</label>
                <label class="tab" @click="tab = 'active'" :class="{ active: tab == 'active' }">Active Hosts</label>
                <label class="tab" @click="tab = 'pending'" :class="{ active: tab == 'pending' }">Pending / Requested</label>
            </div>
            <div class="active" v-if="tab == 'plan'">
                <div class="table" v-if="plans.length > 0">
                    <div class="headings plan">
                        <h3 class="heading">Name</h3>
                        <h3 class="heading">Price</h3>
                        <h3 class="heading">Attributes</h3>
                        <h3 class="heading">Visibility</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="project plan" v-for="plan in plans" :key="plan.id">
                        <label class="data">{{ plan.name }}</label>
                        <label class="data">{{ plan.price }}</label>
                        <label class="data">
                            <p v-for="(attribute,index) in plan.attributes" :key="index">- {{ attribute }}</p>
                        </label>
                        <label class="data visibility" :class="{ 'public' : plan.visibility == 'public' }">
                            {{ plan.visibility }}
                        </label>
                        <div class="data actions">
                            <button class="btn" @click="openModal('view', plan, 'plan')">View</button>
                            <button class="btn" @click="openModal('edit', plan, 'plan')">Edit</button>
                            <button class="btn del" @click="openModal('delete', plan, 'plan')">Delete</button>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No projects available</label>
            </div>
            <div class="active" v-if="tab == 'active'">
                <div class="table" v-if="hostings.length > 0">
                    <div class="headings hosting">
                        <h3 class="heading">Project name</h3>
                        <h3 class="heading">Domain name</h3>
                        <h3 class="heading">Hosting plan</h3>
                        <h3 class="heading">Status</h3>
                        <h3 class="heading">Expiry date</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="project hosting" v-for="project in monthlyRenewals" :key="project.id">
                        <label class="data hover" @click="$router.push(`/admin/hosting/${project.id}/${hyphenName(project.name)}`)">{{ project.name }}</label>
                        <label class="data">{{ project.domain }}</label>
                        <label class="data">{{ project.hosting.name }}(${{ project.hosting.price }})</label>
                        <label class="data">{{ project.status }}</label>
                        <label class="data" v-if="project.domain_exp">{{ project.domain_exp }}</label>
                        <label class="data" v-else>N/A</label>
                        <div class="data actions">
                            <button class="btn" @click="openModal('edit', project, 'active')">Edit</button>
                            <button class="btn del" @click="openModal('delete', project, 'active')">Delete</button>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No current hostings</label>
            </div>
            <div class="active" v-if="tab == 'pending'">
                <div class="table" v-if="requests.length > 0">
                    <div class="headings requests">
                        <h3 class="heading">Project name</h3>
                        <h3 class="heading">Domain name</h3>
                        <h3 class="heading">Hosting plan</h3>
                        <h3 class="heading">Request Date</h3>
                        <h3 class="heading"></h3>
                    </div>
                    <div class="project requests" v-for="project in requests" :key="project.id">
                        <label class="data" v-if="project">{{ project.name }}</label>
                        <label class="data">{{ project.domain }}</label>
                        <label class="data" v-if="project.hosting">{{ project.hosting.name }}(${{ project.hosting.price }})</label>
                        <label v-else>N/A</label>
                        <label class="data">{{ project.date }}</label>
                        <div class="data actions">
                            <button class="btn" @click="openModal('view', project, 'request')">View</button>
                            <button class="btn" @click="openModal('approve', project, 'request')">Approve</button>
                            <button class="btn del" @click="openModal('decline', project, 'request')">Decline</button>
                        </div>
                    </div>
                </div>
                <label v-else class="nodata">No requests received</label>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Modal from './components/HostingModal.vue';
export default {
    name: "AdminHosting",
    components: { Modal },
    data() {
        return {
            loading: false,
            modal: false,
            modalform: null,
            tab: 'plan',
            month: "",
            form: {
                name: "",
                type: "",
                description: "",
                start_date: "",
                service_id: "",
                budget: "",
                hosting_fees: "",
                user_id: ""
            },
            requests: [],
            hostings: [],
            plans: []
        }
    },
    computed: {
        monthlyRenewals(){
            if(this.month == "") return this.hostings;
            return this.hostings.filter(hosting => {
                return new Date(hosting.domain_exp).getMonth() + 1 == this.month;
            })
        }
    },
    methods:{
        closeModal(){
            this.modal = false;
            this.modalform = null;
            this.form = {
                status: ""
            }
        },
        hyphenName(name){
            return name.replace(/\s+/g, '-').toLowerCase();
        },
        openModal(type, content, modalform){
            this.modal = true;
            this.modalform = { type: type, form: modalform };
            type != 'add' ? { ...this.form } = content : ''
        },
        getHostings(type){
            if(type == 'all'){
                this.getAllHostings();
                this.getAllHostingRequests();
            } else if(type == 'plan'){
                this.$getAllHostingPlans();
            } else if(type == 'active') {
                this.getAllHostings();
            } else if(type == 'request') {
                this.getAllHostingRequests();
            }
        },
        getAllHostingRequests(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_HOSTING_REQUESTS')
                .then(res => {
                    this.loading = false;
                    this.requests = res.data;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        },
        getAllHostings(){
            this.loading = true;
            this.$store.dispatch('GET_ALL_ACTIVE_HOSTINGS')
                .then(res => {
                    this.loading = false;
                    this.hostings = res.data;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        }
    },
    mounted() {
        this.getAllHostings();
        this.getAllHostingRequests();
        this.$getAllHostingPlans()
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/main.scss";
.detailed-content{
    padding: 20px;
    .tabs{
        display: flex;
        align-items: center;
        margin: 20px 0px;
        column-gap: 30px;
        .tab{
            font-size: 0.8rem;
            font-weight: 500;
            color: #333;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: .6s;
            &:hover{
                background-color: #f1f1f1;
            }
            &.active{
                background-color: #f5a62361;
                color: #905b06;
            }
        }
    }
    .table{
        margin: 20px;
        .headings{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1.5fr;
            column-gap: 10px;
            padding: 10px 0;
            margin: 0 0 15px;
            border-bottom: 1px solid #dbdbdb8b;
            &.plan{
                grid-template-columns: 0.5fr 0.5fr 1.5fr 0.5fr 1fr;
            }
            &.requests{
                grid-template-columns: 0.6fr 0.6fr 0.6fr 0.5fr 1fr;
            }
            .heading{
                font-size: 0.8rem;
                font-weight: 500;
                color: #333;
            }
        }
        .project{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1.5fr;
            align-items: center;
            column-gap: 10px;
            margin: 0 0 15px;
            padding: 10px 0;
            border-bottom: 1px solid #dbdbdb54;
            &.plan{
                grid-template-columns: 0.5fr 0.5fr 1.5fr 0.5fr 1fr;
            }
            &.requests{
                grid-template-columns: 0.6fr 0.6fr 0.6fr 0.5fr 1fr;
            }
            .data{
                font-size: 0.8rem;
                color: #333;
                height: fit-content;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                &.hover{
                    cursor: pointer;
                    &:hover{
                        color: #f5a623;
                        text-decoration: underline;
                    }
                }
                &.img{
                    img{
                        width: 100px;
                        height: 60px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                }
                &.link{
                    a{
                        svg{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &.status{
                    width: fit-content;
                    padding: 3px 10px;
                    border-radius: 4px;
                    &.completed{
                        background: #0984e361;
                        color: #0984e3;
                    }
                    &.ongoing{
                        background: #f5235f61;
                        color: #d61d61;
                    }
                }
                &.actions{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 10px;
                    button{
                        padding: 5px 20px;
                        cursor: pointer;
                        border: none;
                        border-radius: 5px;
                        background-color: #f1f1f1;
                        font-size: 0.7rem;
                        font-weight: 500;
                        color: #333;
                        cursor: pointer;
                        transition: .6s;
                        &:hover{
                            background-color: #ccc;
                        }
                        &.del{
                            background-color: salmon;
                            color: #fff;
                        }
                    }
                }
                &.visibility{
                    color: #58092f;
                    background-color: #de8b7c;
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: fit-content;
                    text-transform: capitalize;
                }
                &.public{
                    color: #09580c;
                    background-color: #7cde7f;
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: fit-content;
                }
            }
        }
    }
}
</style>